import '../../Styles/restauration5.scss'


export default function Restauration5 ({image1, image2, image3,description}) {
    return(
        <div className="restauration5">
            <img src={image1} alt="" className="restauration5-image1"/>
            <div className="restauration5-main-content">
                <img src={image2} alt="" className="restauration5-main-content-image2"/>
                <div className="restauration5-main-content-corpus">
                    <div className="restauration5-main-content-corpus-title">
                        <h3>C'est bien beau tout ça mais ...</h3>
                        <h2>Qu'est-ce qu'on mange?</h2>
                    </div>
                    <div className="restauration5-main-content-text">{description}</div>
                </div>
                <img src={image3} alt="" className="restauration5-main-content-image3"/>
            </div>
        </div>
    )
}