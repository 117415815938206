import '../../Styles/village5.scss';
import '../../Styles/village.scss';
import village5 from "../../Assets/icons/villages/village5.png";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import fouettardBoucher from "../../Assets/fouettardBoucher.png";
import enfants from "../../Assets/enfants.png";
import VillagePresentation from "../../Components/VillagePresentation/villagePresentation";
import Article1 from "../../Components/Article1/article1";
import quiche from '../../Assets/restauration/quiche.png';
import platCarottes from '../../Assets/restauration/platCarottes.png';
import vin from '../../Assets/restauration/vin.png';
import arabesque from "../../Assets/arabesque.png";
import Carousel from "../../Components/Carousel/carousel";
import hammeauCraffe from '../../Assets/villages/hammeauCraffe.png';
import jardinCitadelle from '../../Assets/villages/jardinCitadelle.png'
import React, {useContext, useRef} from "react";
import Article3 from "../../Components/Article3/article3";
import fortifications from '../../Assets/villages/fortifications.png'
import Restauration5 from "../../Components/Restauration5/restauration5";
import ligne12 from "../../Assets/icons/lignes/12.png";
import ligne13 from "../../Assets/icons/lignes/13.png";
import ligne16 from "../../Assets/icons/lignes/16.png";
import citadine from "../../Assets/icons/lignes/citadine.png";
import resago from "../../Assets/icons/lignes/resago.png";
import flexo from "../../Assets/icons/lignes/flexo.png";
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import L from "leaflet";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";
import {useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";

export default function Village5(){

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);
    const {typeNameToObject, lieux} = useContext(LieuxContext);


    const mapRef = useRef();

    const parcoursTitle = (
        <>
            Le Hameau de <br />
            la Craffe
        </>
    );

    const intro= (
        <>
            La majestueuse porte de la Craffe se dresse devant les enfants. <br/>
            <br/>
            « C’est ici que tout doit se terminer », déclare Saint Nicolas d’une voix solennelle. Mais alors qu’il
            s’apprête à enfermer le Père Fouettard et le Boucher dans les geôles anciennes, un groupe de musiciens
            entonne une chanson festive.
        </>
    )

    const jardin = (
        <>
            Sur les remparts de la ville vieille, adossée à la porte de la citadelle édifiée en 1598 par Charles III, se cache un jardin secret. De style médiéval mais sous influence de la renaissance, cet espace de 1270 m² était en friche jusqu'en 1990.
            Propriété du rectorat de l'académie de Nancy-Metz, il a été entièrement aménagé par le service des Parcs et Jardins et est accessible toute l’année, de 8h à 19h, pour ceux qui trouvent l’entrée ! (On vous donne un indice : un passage a été creusé dans l’ancienne fortification du côté de la rue Deglin).<br/>
            <br/>
            Aujourd’hui, des carrés de végétaux utilitaires (plantes aromatiques, médicinales et fruitières) ou anciens (chèvre-feuilles, rosiers) se mêlent aux buis taillés en dessin géométrique. Et dès l'arrivée des beaux jours, on apprécie les odeurs de menthe, de thym ou encore de chèvrefeuille... <br/>
            Les grands arbres de la propriété, qui ont été conservés, contribuent à faire de ce jardin secret un lieu idéal de repos et de sérénité.

        </>
    )

    const restauration5 = (
        <>
            Le Hameau vous réserve des boissons régionales (du <strong>vin des Côtes de Toul</strong>, du <strong>vin de Moselle</strong>, des <strong>jus de fruits chauds ou froids</strong>...) et des produits locaux de qualité comme des <strong>Pâtés Lorrains</strong>, des <strong>quiches
            maisons</strong>, du <strong>saumon gravelax</strong>, du <strong>foie gras</strong>... Le plus dur (après avoir trouvé une place), c’est de choisir !
        </>
    )

    const hammeauCraffeDesc= (
        <>
            Volupté et raffinement au menu de ce hameau géré par le restaurateur de “Le Vivier” et “Carte Blanche” tout en douceur, niché sous la Porte de la Craffe ! <br/>
            <br/>
            <i>Vibe</i> relaxante et cocooning garanties
        </>
    )

    const vestigesFortifications= (
        <>
            À la fin du XVIe siècle, le Duc Charles III fait construire la porte de la Citadelle protégée par deux bastions : le bastion le Marquis (en hommage au futur Henri II) et le bastion le Duc (Charles III). <br/>
            <br/>
            Durant la guerre de trente ans en 1633, Nancy est occupée par la France. La ville est rendue à la Lorraine sous la condition du démantèlement des fortifications, mais repassera à nouveau à la France pour quelques années sous le règne de Louis XIV, qui confie aux soins de Vauban la reconstruction des fortifications. C'est finalement le traité de Ryswick (1697) qui restitue la ville au duc de Lorraine, Léopold. Les défenses de la Ville-Neuve doivent être à nouveau détruites et celles de la Ville-Vieille peuvent être conservées. À la fin du XIXème siècle, ces 2 bastions désaffectés sont, cette fois, définitivement arasés.<br/>
            <br/>
            Pourtant, au n°21 rue Sellier, une "rue particulière" est bordée par un tronçon de mur garni de deux ouvertures. Ce mur est constitué d’un appareillage de blocs de pierre sur une longueur d’environ 20 m... Il s’agirait du dernier vestige du « bastion Le Duc » démantelé dans les années 1880, dans le cadre de l’urbanisation du secteur sud-est du Faubourg des Trois-Maisons.
        </>
    )

    const lignes5 = [
        {icon : ligne12, alt: 'ligne 12'},
        {icon : ligne13, alt: 'ligne 13'},
        {icon : ligne16, alt: 'ligne 16'},
        {icon : citadine, alt: 'citadine'},
        {icon : resago, alt: 'résa go'},
        {icon : flexo, alt: 'flexo soirée'},
    ]

    const infoVillage5 = {
        dates: {
            opening: '23/11',
            closing: '05/01'
        },
        schedule: [
            {
                type:'single',
                days:['Mercredi et jeudi'],
                hours : '16h - 22h'
            },
            {
                type:'single',
                days:['Vendredi et samedi'],
                hours : '11h - 23h'
            },
            {
                type:'single',
                days:['Dimanche'],
                hours : '11h - 21h'
            }
        ],
        closed : "Fermeture les lundis et mardis",
        adresse : "Place Vaudémont, 54000 Nancy",
        transport : "Amerval"
    }

    return(
        <div className="village5 village">
            {/*Header with audio player*/}
            <div className="village5-header">
                <div className="titre-village">
                    <img src={village5} alt=""/>
                    <h1 className="etape-header-title">{parcoursTitle}</h1>
                </div>

                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} link={"/"} image1={fouettardBoucher} image2={enfants}/>
            </div>

            {/*Village description*/}
            <div className="presentation-village">
                <VillagePresentation place='Sous la portez de la Craffe' image={hammeauCraffe} description={hammeauCraffeDesc} information='Informations sur le Hameau' lieu={infoVillage5} lignes={lignes5} />
            </div>

            {/*jardin de la citadelle*/}
            <div className='ventre-nancy'>
                <Article1 subtitle="Le jardin de la Citadelle" title="Le jardin secret de la Ville Vieille"
                          description={jardin} image={jardinCitadelle}
                          mention=""/>
            </div>

            {/*vieille ville*/}
            <div className='villeVille'>
                <Article3 subtitle="Le vestige des fortifications" title="Encore un que Louis XIV n'aura pas !"
                          description={vestigesFortifications} image={fortifications} mention=""/>
            </div>


            {/*Restauration*/}
            <div className='village1-restauration'>
                <Restauration5 image1={quiche} image2={platCarottes} image3={vin} description={restauration5}/>
            </div>

            {/*Arabesque*/}
            <div className="arabesque">
                <img src={arabesque} alt=""/>
            </div>

            {/*Evenements*/}
            <div className="village1-carousel">
                <h3>Les événements à proximité</h3>
                <h2>Ne pas rater:</h2>
                <Carousel/>
            </div>

            {/*/!*TODO : patrimoine proche*!/*/}

            <div className="village-carte">
                <div className="village-carte-titre">À (re)découvrir</div>
                <div className="village-carte-soustitre">Le patrimoine proche</div>
                <div className="village-carte-carte">
                    {
                        lieux.length > 0 &&
                        <MapContainer ref={mapRef} center={etapes.find((etape) => etape.id === etapeCourante).coords}
                                      zoom={20} scrollWheelZoom={true} style={{
                            width: '100%',
                            height: '100%',
                        }} whenReady={(e) => {
                            e.target.on('popupopen', function (e) {
                                if (e.popup.options.id.length === 1) {
                                    setEtapeCourante(e.popup.options.id);
                                }
                                e.target.closePopup();
                                e.popup.options.id === "1" ?
                                    navigate("/village1") :
                                    e.popup.options.id === "2" ?
                                        navigate("/village2") :
                                        e.popup.options.id === "3" ?
                                            navigate("/village3") :
                                            e.popup.options.id === "4" ?
                                                navigate("/village4") :
                                                e.popup.options.id === "5" ?
                                                    navigate("/village5") :
                                                    e.popup.options.id === "6" ?
                                                        navigate("/village6") :
                                                        e.popup.options.id === "7" ?
                                                            navigate("/village7") :
                                                            e.popup.options.id === "8" ?
                                                                navigate("/village8") :
                                                                navigate("/lieu/" + e.popup.options.id);
                            });
                        }}>

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                            />

                            {
                                etapes.map((etape) => {
                                    return (
                                        <Marker position={etape.coords} icon={L.icon({
                                            iconUrl: etape.id === "1" ? v1 :
                                                etape.id === "2" ? v2 :
                                                    etape.id === "3" ? v3 :
                                                        etape.id === "4" ? v4 :
                                                            etape.id === "5" ? v5 :
                                                                etape.id === "6" ? v6 :
                                                                    etape.id === "7" ? v7 :
                                                                        v8,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={etape.id}>
                                                {etape.titre}
                                            </Popup>
                                            <Tooltip direction="top">
                                                {etape.titre}
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                lieux?.map((lieu) => {
                                    return (
                                        <Marker position={lieu?.coords} icon={L.icon({
                                            iconUrl: typeNameToObject(lieu.type).icon,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={lieu.name}>
                                                {lieu.name}
                                            </Popup>
                                            <Tooltip direction="top">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "150px",
                                                }}>
                                                    <img style={{
                                                        width: "150px",
                                                        height: "112px",
                                                        objectFit: "cover",
                                                    }}
                                                         src={"https://preprodback-istorio.monolithe.studio/files/" + lieu?.imgs[0]}
                                                         alt="vignette lieu"/>
                                                    <div style={{
                                                        textWrap: "wrap",
                                                        fontWeight: "bold",
                                                        marginTop: "5px",
                                                    }}>
                                                        {lieu.name}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }

                        </MapContainer>
                    }
                </div>
            </div>

            {/*/!*TODO : carousel partenaire*!/*/}

            {/*<div className='modale-infos-village'>*/}
            {/*    <InfoModal lieu="Place Vaudémont, 54000 Nancy" arret="Amerval" lignes={lignes4} />*/}
            {/*</div>*/}
        </div>
    )
}