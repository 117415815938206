import React, { createContext } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Components/Navbar/Navbar';
import Footer from "../Components/Footer/footer";

const FooterContext = createContext();

export const FooterProvider = ({ children }) => {
    const location = useLocation();
    const showFooter = location.pathname !== '/';

    return (
        <FooterContext.Provider value={{ showFooter }}>
            <>
                <Navbar />
                {children}
                {showFooter && <Footer />}
            </>
        </FooterContext.Provider>
    );
};
