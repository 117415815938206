import '../../Styles/parcoursDescription.scss';
import {Link} from "react-router-dom";
import {useState} from "react";


export default function ParcoursDescription({text, text2, image1, image2}) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return(
        <div className="parcours-description-component">
            <img src={image1} alt="" className='parcours-description-component-image1'/>
            <p className="parcours-description-text">{text}</p>
            <p className="parcours-description-text" style={isExpanded ?
                {display: "block"} :
                {display: 'none'}}>{text2}</p>
            <div className="parcours-description-link">
                <Link to={''} className="parcours-description-link-button" onClick={toggleExpansion}>{isExpanded ?
                    'Lire moins' :
                    'Lire la suite'}</Link>
            </div>

            <img src={image2} alt="" className='parcours-description-component-image2'/>

        </div>
    )

}
