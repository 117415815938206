import {createContext, useEffect, useState} from "react";
import ARCREM from "../Assets/icons/Architecture remarquable.png";
import CHAPAL from "../Assets/icons/Château Palais.png";
import MONCOM from "../Assets/icons/Monument commémoratif.png";
import PATIND from "../Assets/icons/Patrimoine industriel.png";
import PATMIL from "../Assets/icons/Patrimoine militaire.png";
import PATNAT from "../Assets/icons/Patrimoine naturel.png";
import PATREL from "../Assets/icons/Patrimoine religieux.png";
import PONOUV from "../Assets/icons/Pont ouvrages dart.png";
import SITARC from "../Assets/icons/Site archéologique.png";
import STRART from "../Assets/icons/Street Art.png";


export const LieuxContext = createContext();

export function LieuxContextProvider({children}) {
    const [types, setTypes] = useState([
        {
            name: "Architecture remarquable",
            icon: ARCREM
        },
        {
            name: "Château / Palais",
            icon: CHAPAL
        },
        {
            name: "Monument commémoratif",
            icon: MONCOM
        },
        {
            name: "Patrimoine industriel",
            icon: PATIND
        },
        {
            name: "Patrimoine militaire",
            icon: PATMIL
        },
        {
            name: "Patrimoine naturel",
            icon: PATNAT
        },
        {
            name: "Patrimoine religieux",
            icon: PATREL
        },
        {
            name: "Pont & ouvrages d'art",
            icon: PONOUV
        },
        {
            name: "Site archéologique",
            icon: SITARC
        },
        {
            name: "Street Art",
            icon: STRART
        },
    ]);
    const typeNameToObject = (name) => {
        return types.find(type => type.name === name);
    }


    const [lieux, setLieux] = useState([]);

    useEffect(() => {
        fetch("https://preprodback-istorio.monolithe.studio/lieux/all/", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(data => {
                let newLieux = data;
                Promise.all(newLieux.map(lieu => {
                    return fetch("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAH44OK_htVCq6fNTLKD-m1DLUQ3x_7YY8&address=" + lieu?.adress, {
                        method: "GET",
                    })
                        .then(response => response.json())
                        .then(data => {
                            lieu.coords = [
                                parseFloat(data.results[0].geometry.location.lat),
                                parseFloat(data.results[0].geometry.location.lng),
                            ];
                            return lieu;
                        });
                })).then(updatedLieux => {
                    setLieux(updatedLieux);
                });
            })
            .catch(err => {
                console.error("Erreur lors de la récupération des lieux:", err);
            });
    }, []);
    return (
        <LieuxContext.Provider value={{typeNameToObject, lieux}}>
            {children}
        </LieuxContext.Provider>
    );
};