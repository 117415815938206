import '../../Styles/parcours.scss';
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import React, {useContext} from "react";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import Saint_Nicolas from '../../Assets/dessins/Saint_Nicolas.png';
import enfants from '../../Assets/enfants.png';
import etoileFilante from '../../Assets/etoileFilante.png';
import ane from '../../Assets/ane.png';
import Carousel from "../../Components/Carousel/carousel";
import AudioPlayer from "../../Components/AudioPlayer/audioPlayer";
import {MapContainer, TileLayer, Marker, Popup, Tooltip, Rectangle} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";
import {useNavigate} from "react-router-dom";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";


export default function Parcours () {

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);

    const {typeNameToObject} = useContext(LieuxContext)

    const parcoursTitle = (
        <>
            Rififi et course-poursuite<br />
            dans les villages de la Saint-Nicolas !
        </>
    );

    const intro= (
        <>
            “Il était trois petits enfants qui s’en allaient glâner aux champs...”<br/>
            <br/>
            Vous connaissez certainement la légende du Bon Saint-Nicolas, dans laquelle le Patron de la
            Lorraine<br/>
            sauva trois petits enfants des griffes de l’horrible boucher.<br/>
            Mais... Avez-vous déjà eu la curiosité de connaitre la suite de cette incroyable histoire ?
        </>
    )

    const eventTitle=("les principaux événements").toUpperCase();

    return (
        <div className="parcours">
            {/*Header with audio player*/}
            <div className="parcours-header">
                <h1 className="parcours-header-title">{parcoursTitle}</h1>
                <AudioReader track={petitsEnfants} color={'#8A7446'}/>
                {/*<AudioPlayer element={petitsEnfants} />*/}
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} link={"/"} image1={Saint_Nicolas}/>
            </div>

            {/*Interactive card*/}
            <div className="plans-des-villages">
                <h2 className="plans-des-villages-title">LE PLAN DES VILLAGES</h2>
                <div className="plan-des-villages-description-images">
                    <p className="plan-des-villages-description">Suivez la trace des trois petits enfants à travers les
                        huit villages et hameaux gourmands disséminés dans les rues de Nancy. À chaque étape, des
                        surprises vous attendent. À vous de jouer !
                    </p>
                </div>

                {/*interactive card emplacement*/}
                <div className="plan-des-villages-carte">

                    <div className="plan-des-villages-images">
                        <img src={etoileFilante} alt=" étoile filante" className="plan-des-villages-image1"/>
                        <img src={enfants} alt="3 petis enfants" className="plan-des-villages-image2"/>
                    </div>
                    <MapContainer center={[48.692054, 6.184417]} zoom={15} scrollWheelZoom={true} style={{
                        width: '100%',
                        height: '100%',
                    }} whenReady={(e) => {
                        e.target.on('popupopen', function (e) {
                            setEtapeCourante(e.popup.options.id);
                            e.popup.options.id === "1" ?
                                navigate("/village1") :
                                e.popup.options.id === "2" ?
                                    navigate("/village2") :
                                    e.popup.options.id === "3" ?
                                        navigate("/village3") :
                                        e.popup.options.id === "4" ?
                                            navigate("/village4") :
                                            e.popup.options.id === "5" ?
                                                navigate("/village5") :
                                                e.popup.options.id === "6" ?
                                                    navigate("/village6") :
                                                    e.popup.options.id === "7" ?
                                                        navigate("/village7") :
                                                        navigate("/village8");
                        });
                    }}>

                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                        />

                        {
                            etapes.map((etape) => {
                                return (
                                    <Marker position={etape.coords} icon={L.icon({
                                        iconUrl: etape.id === "1" ? v1 :
                                            etape.id === "2" ? v2 :
                                                etape.id === "3" ? v3 :
                                                    etape.id === "4" ? v4 :
                                                        etape.id === "5" ? v5 :
                                                            etape.id === "6" ? v6 :
                                                                etape.id === "7" ? v7 :
                                                                    v8,
                                        iconSize: [30, 30],
                                        iconAnchor: [15, 30],
                                        popupAnchor: [0, -30],
                                    })}>
                                        <Popup id={etape.id}>
                                            {etape.titre}
                                        </Popup>
                                        <Tooltip>
                                            {etape.titre}
                                        </Tooltip>
                                    </Marker>
                                )
                            })
                        }

                    </MapContainer>
                </div>
            </div>

            {/*Principal events*/}
            <div className="principaux-evenement">
                <div className="principaux-evenements-title">
                    <img src={ane} alt="âne du Saint Nicolas"/>
                    <h2 className="evenements-title">{eventTitle}</h2>
                </div>

                <div className="principaux-evenement-carousel">
                    <Carousel/>
                </div>



            </div>

        </div>
    )
}