import '../../Styles/village7.scss'
import '../../Styles/village.scss'
import village7 from "../../Assets/icons/villages/village7.png";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import fouettardBoucher from "../../Assets/fouettardBoucher.png";
import enfants from "../../Assets/enfants.png";
import VillagePresentation from "../../Components/VillagePresentation/villagePresentation";
import Article1 from "../../Components/Article1/article1";
import vinChaud2 from '../../Assets/restauration/vinChaud2.png';
import poutine from '../../Assets/restauration/poutine.png';
import arabesque from "../../Assets/arabesque.png";
import Carousel from "../../Components/Carousel/carousel";
import hameauVosges from '../../Assets/villages/hameauVosges.png';
import porteStNicolas from '../../Assets/villages/porteStNicolas.png'
import React, {useContext, useRef} from "react";
import Article3 from "../../Components/Article3/article3";
import quartierHopitaux from '../../Assets/villages/quartierHopitaux.png'
import Restauration4 from "../../Components/Restauration4/restauration4";
import ligne12 from "../../Assets/icons/lignes/12.png";
import ligne13 from "../../Assets/icons/lignes/13.png";
import ligne16 from "../../Assets/icons/lignes/16.png";
import citadine from "../../Assets/icons/lignes/citadine.png";
import resago from "../../Assets/icons/lignes/resago.png";
import flexo from "../../Assets/icons/lignes/flexo.png";
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import L from "leaflet";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";
import {useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";

export default function Village7(){

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);
    const {typeNameToObject, lieux} = useContext(LieuxContext);


    const mapRef = useRef();

    const parcoursTitle = (
        <>
            Le Hameau gourmand <br />
            des Vosges
        </>
    );

    const intro= (
        <>
            La course effrénée des trois petits aventuriers les mène jusqu’à la majestueuse Porte Saint-
            Nicolas, scintillante avec toutes ses guirlandes lumineuses. Leurs souffles forment des petits
            nuages blancs dans l’air glacé, mais ils n’ont pas le temps de s’arrêter. Derrière eux, le Père
            Fouettard, tenace malgré ses mésaventures, avance en grognant, le manteau toujours couvert de
            neige et de suie.
        </>
    )

    const introFullText = (
        <>
            « Vous ne m’échapperez pas cette fois-ci ! », hurle-t-il, sa voix rauque résonnant dans la nuit. Il
            s’élance à leur poursuite, mais ses bottes glissantes ne lui rendent toujours pas la tâche facile. Il
            va devoir trouver un autre moyen d’attraper les enfants si il ne veut pas passer son temps par
            terre.<br/>
            <br/>
            Le Hameau Gourmand s’étend tout autour de la Porte et semble sortir tout droit d’un conte : des
            chalets en bois décorés de guirlandes, des fumées qui s’échappent des stands de vin chaud et
            de marrons grillés. Mais pour le Père Fouettard, ce décor magique est un véritable champ de
            bataille. Repérant un stand de vin chaud déserté temporairement par son marchand, il se
            précipite pour s’y installer. Il coiffe un bonnet rouge trouvé sur place et enfile un tablier taché de jus de fruits, prêt à incarner le parfait marchand. Derrière le comptoir, il tente de repérer les
            enfants mais il n’avait pas prévu toutes les demandes des visiteurs assoiffés.<br/>
            <br/>
            « Un vin chaud, s’il vous plait ! », s’exclame un passant.<br/>
            <br/>
            « Un autre ici ! », enchéri un autre.<br/>
            <br/>
            Le Père Fouettard, pris au piège de sa propre ruse, s’affaire derrière le comptoir avec maladresse.
            Il verse le vin chaud à la hâte, renversant autant de liquide sur le comptoir que dans les gobelets.
            Les clients, amusés par son inexpérience, le bombardent de nouvelles commandes. Le spectacle
            attire de plus en plus de monde.<br/>
            <br/>
            Pendant ce temps, les trois enfants s’arrêtent de courir.<br/>
            <br/>
            « On dirait qu’on l’a semé ! », souffle Claudon, les mains sur ses genoux pour reprendre son
            souffle.<br/>
            <br/>
            Jacquot regarde autour de lui.<br/>
            « Il faut rester prudents. On le connaît, il ne va pas abandonner aussi facilement. »<br/>
            <br/>
            Philippe, lui, se laisse distraire par un stand de vin chaud et de jus de pommes, où un marchand
            semble amuser la galerie.<br/>
            « Allez, on prend une pause ! », s’exclame Philippe. « Un jus de pomme chaud, ça nous fera du
            bien. »<br/>
            <br/>
            Philippe entraîne ses deux amis vers le stand, l’air alléchés par l’odeur de jus de pomme chaud.
            Derrière le comptoir, le marchand maladroit les observe avec un sourire malveillant qui aurait dû
            les alerter. Mais les trois aventuriers, encore essoufflés par leur course, baissent leur garde.<br/>
            <br/>
            « Trois jus de pomme, s’il vous plaît ! » demande Philippe, sans se méfier.<br/>
            Le prétendu marchand, qui n’est autre que le Père Fouettard, leur tend trois gobelets fumants en
            ricanant. Claudon et Jacquot commencent à boire, savourant la chaleur réconfortante de la
            boisson, tandis que Philippe, plus curieux, lève les yeux vers le visage couvert de suie du
            vendeur.<br/>
            <br/>
            « Attends une seconde… » murmure Philippe, en reculant légèrement.<br/>
            Mais avant qu’il puisse alerter ses amis, le Père Fouettard bondit de l’autre côté du comptoir et
            attrape Philippe par le bras.<br/>
            <br/>
            « Ha ! Je te tiens, petit garnement ! » rugit-il, son bonnet rouge tombant pour révéler sa tignasse
            en désordre.<br/>
            <br/>
            Jacquot et Claudon reculent, surpris de découvrir l’identité du vendeur.<br/>
            « Lâche-le ! » crie Claudon, les yeux écarquillés.<br/>
            <br/>
            Les passants, jusque-là amusés par les maladresses du marchand, se figent en découvrant la
            scène. L’air, un instant léger et festif, se charge d’une tension palpable. Mais alors que le Père
            Fouettard commence à traîner Philippe à l’arrière du chalet, un halo dorée illumine la place.
            Un murmure traverse la foule, et tous les regards se tournent vers l’origine de cette lumière. Là, au
            centre du Hameau Gourmand, apparaît une silhouette drapée de blanc et de rouge, une crosse
            scintillante à la main. Saint Nicolas avance calmement, son visage empreint de sérénité.<br/>
            <br/>
            « Père Fouettard ! Relâche cet enfant immédiatement » dit-il d’une voix douce mais profonde, qui
            semble résonner dans l’air glacé.<br/>
            Le Père Fouettard, pris au dépourvu, lâche Philippe et se fige, comme un enfant pris en faute.
            « Mais… mais… ils n’ont pas été sages ! » balbutie-t-il, en pointant un doigt tremblant vers les
            trois enfants.<br/>
            <br/>
            Saint Nicolas s’approche et pose une main apaisante sur l’épaule du Père Fouettard.
            « Allons mon vieil ami, tu sais bien que ce n’est pas ainsi que l’on enseigne les leçons. Ces
            enfants n’ont pas besoin d’être punis, mais guidés… »<br/>
            <br/>
            Puis, se tournant vers les enfants, Saint Nicolas leur adresse un sourire chaleureux.
            « N’ayez crainte, mes petits amis. Vous êtes sous ma protection. »<br/>
            <br/>
            Jacquot s’avance timidement.<br/>
            « Merci, Saint Nicolas. »<br/>
            <br/>
            Saint Nicolas jette un regard bienveillant au Père Fouettard.<br/>
            « Il a parfois du mal à comprendre que chacun mérite une chance. Mais il est là pour apprendre,
            comme nous tous. »<br/>
            <br/>
            Puis, d’un geste de sa crosse, il fait apparaître des gâteaux et des friandises sur le comptoir
            déserté.<br/>
            « Et si nous transformions cette aventure en un moment de partage ?"<br/>
            Le Père Fouettard, penaud mais soulagé, hoche la tête et retourne derrière le comptoir pour servir
            les passants, cette fois sous la supervision de Saint Nicolas. Les enfants, rassurés, reçoivent
            chacun un pain d’épices et un nouveau gobelet de jus chaud, que Saint Nicolas leur tend en
            personne.<br/>
            <br/>
            « Continuez votre chemin, mes petits aventuriers, dit-il en leur faisant un clin d’oeil. Mais
            souvenez-vous : même dans l’obscurité, une lumière peut toujours vous guider. »<br/>
            Le trio s’éloigne, leurs coeurs réchauffés par cette rencontre, tandis que derrière eux, le Hameau
            Gourmand retrouve sa magie, illuminé par la présence bienveillante de Saint Nicolas.
        </>
    )

    const porteSaintNicolas = (
        <>
            Comme les autres portes de la ville construites à cette époque (notamment la porte Saint-Georges, sauvée de la destruction par un certain Victor Hugo), la porte Saint-Nicolas était percée, en son centre, d'une large ouverture en plein cintre, et de chaque côté par deux ouvertures réduites destinées à l'usage des piétons, dans un style qui reste empreint de la Renaissance. <br/>
            <br/>
            Mais au fait, pourquoi ce nom ? <br/>
            C'est de St Nicolas de Port, le dimanche 5 janvier 1477 que le duc de Lorraine René II partit libérer Nancy assiégée, après avoir assisté à la messe et demandé la protection de saint Nicolas.<br/>
            <br/>
            Vainqueur du duc de Bourgogne Charles le Téméraire, il confirma officiellement saint Nicolas comme Saint Patron des Lorrains et fit de l’emblème de sa famille, la croix d’Anjou (qu’il arbora contre la croix de Saint-André des Bourguignons) l’emblème de la ville (aujourd’hui empruntée par sa voisine du nord, qui aime se l’approprier de temps en temps sous le regard amusé des nancéiens).<br/>
            <br/>
            Cette porte, dont le fronton rend hommage à la “bataille de Nancy”, est l’un des rares témoignages restant d’un réseau de fortifications extrêmement puissantes que Louis XIV préféra raser (puis reconstruire, puis raser, puis reconstruire...) plutôt que de les voir narguer son royaume de France.
        </>
    )

    const hopitaux = (
        <>
            Rue des 4 églises (3 églises et une chapelle de couvent jallonaient cette rue), foyer Sainte-Marie, Congrégation de Saint-Charles (ordre fondé en 1652), Noviciat des Jésuites (1602, photo ci-contre), église Saint-Pierre, matérnité, hôpitaux... <br/>
            <br/>
            La densité exceptionnelle d’établissements religieux et de centres hospitaliers montre que Nancy porte l’humanisme et la santé dans ses gênes. Si certains sont encore actifs, dont l’hôpital central qui vit ses dernières années sur ce site historique avant de rejoindre les hauteurs de Brabois, d’autres attendent une seconde vie à travers des projets de réhabilitation souvent clivants. <br/>
            <br/>
            Définitivement, ce quartier de Nancy avait pour vocation à soigner les maux du corps et de l’âme.
        </>
    )

    const restauration7 = (
        <>
            Envie d’une énorme <strong>tartiflette</strong> bien réconfortante ? D’une
            délicieuse <strong>poutine</strong> bien gourmande ? Ou d’un bon petit <strong>vin chaud ou jus de fruit
            chaud</strong> aux épices délicats ? Vous trouverez votre bonheur sur le Hameau !<br/>
            <br/>
            Et si vous souhaitez vous mettre au chaud, n’hésitez pas à pousser les portes du discret “Big sister”,
            décerné meilleur restaurant vegan de France en 2023 par le magazine Luxlife.
        </>
    )

    const hameauVosgesDesc='Organisé par l’association Les Vedettes du Chardon et Tabarnak, ce joli hameau de trois chalets est idéal pour se r’cinquer grâce à moults spécialités hivernales, golaye de vin chaud  et autres activités fort sympathiques, notamment des concerts et des expositions.'

    const lignes7 = [
        {icon : ligne12, alt: 'ligne 12'},
        {icon : ligne13, alt: 'ligne 13'},
        {icon : ligne16, alt: 'ligne 16'},
        {icon : citadine, alt: 'citadine'},
        {icon : resago, alt: 'résa go'},
        {icon : flexo, alt: 'flexo soirée'},
    ]

    const infoVillage7 = {
        dates: {
            opening: '23/11',
            closing: '05/01'
        },
        schedule: [
            {
                type:'single',
                days:['Mercredi et jeudi'],
                hours : '16h - 22h'
            },
            {
                type:'single',
                days:['Vendredi et samedi'],
                hours : '11h - 23h'
            },
            {
                type:'single',
                days:['Dimanche'],
                hours : '11h - 21h'
            }
        ],
        closed : "Fermeture les lundis et mardis",
        adresse : "Place Vaudémont, 54000 Nancy",
        transport : "Amerval"
    }

    return(
        <div className="village7 village">
            {/*Header with audio player*/}
            <div className="village7-header">
                <div className="titre-village">
                    <img src={village7} alt=""/>
                    <h1 className="etape-header-title">{parcoursTitle}</h1>
                </div>

                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} text2={introFullText} image1={fouettardBoucher} image2={enfants}/>
            </div>

            {/*Village description*/}
            <div className="presentation-village">
                <VillagePresentation place='Porte Saint-Nicolas' image={hameauVosges} description={hameauVosgesDesc}
                                     information='Informations sur le Hameau' lieu={infoVillage7} lignes={lignes7}/>
            </div>

            {/*jardin de la citadelle*/}
            <div className='ventre-nancy'>
                <Article1 subtitle="La Porte Saint-Nicolas" title="Vestige d'une place forte"
                          description={porteSaintNicolas} image={porteStNicolas}
                          mention=""/>
            </div>

            {/*vieille ville*/}
            <div className='villeVille'>
                <Article3 subtitle="Hôpitaux, établissements religieux..." title="Un quartier" description={hopitaux}
                          image={quartierHopitaux} mention=""/>
            </div>


            {/*Restauration*/}
            <div className='village1-restauration'>
                <Restauration4 image2={vinChaud2} image3={poutine} description={restauration7}/>
            </div>

            {/*Arabesque*/}
            <div className="arabesque">
                <img src={arabesque} alt=""/>
            </div>

            {/*Evenements*/}
            <div className="village1-carousel">
                <h3>Les événements à proximité</h3>
                <h2>Ne pas rater:</h2>
                <Carousel/>
            </div>

            {/*/!*TODO : patrimoine proche*!/*/}

            <div className="village-carte">
                <div className="village-carte-titre">À (re)découvrir</div>
                <div className="village-carte-soustitre">Le patrimoine proche</div>
                <div className="village-carte-carte">
                    {
                        lieux.length > 0 &&
                        <MapContainer ref={mapRef} center={etapes.find((etape) => etape.id === etapeCourante).coords}
                                      zoom={20} scrollWheelZoom={true} style={{
                            width: '100%',
                            height: '100%',
                        }} whenReady={(e) => {
                            e.target.on('popupopen', function (e) {
                                if (e.popup.options.id.length === 1) {
                                    setEtapeCourante(e.popup.options.id);
                                }
                                e.target.closePopup();
                                e.popup.options.id === "1" ?
                                    navigate("/village1") :
                                    e.popup.options.id === "2" ?
                                        navigate("/village2") :
                                        e.popup.options.id === "3" ?
                                            navigate("/village3") :
                                            e.popup.options.id === "4" ?
                                                navigate("/village4") :
                                                e.popup.options.id === "5" ?
                                                    navigate("/village5") :
                                                    e.popup.options.id === "6" ?
                                                        navigate("/village6") :
                                                        e.popup.options.id === "7" ?
                                                            navigate("/village7") :
                                                            e.popup.options.id === "8" ?
                                                                navigate("/village8") :
                                                                navigate("/lieu/" + e.popup.options.id);
                            });
                        }}>

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                            />

                            {
                                etapes.map((etape) => {
                                    return (
                                        <Marker position={etape.coords} icon={L.icon({
                                            iconUrl: etape.id === "1" ? v1 :
                                                etape.id === "2" ? v2 :
                                                    etape.id === "3" ? v3 :
                                                        etape.id === "4" ? v4 :
                                                            etape.id === "5" ? v5 :
                                                                etape.id === "6" ? v6 :
                                                                    etape.id === "7" ? v7 :
                                                                        v8,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={etape.id}>
                                                {etape.titre}
                                            </Popup>
                                            <Tooltip direction="top">
                                                {etape.titre}
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                lieux?.map((lieu) => {
                                    return (
                                        <Marker position={lieu?.coords} icon={L.icon({
                                            iconUrl: typeNameToObject(lieu.type).icon,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={lieu.name}>
                                                {lieu.name}
                                            </Popup>
                                            <Tooltip direction="top">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "150px",
                                                }}>
                                                    <img style={{
                                                        width: "150px",
                                                        height: "112px",
                                                        objectFit: "cover",
                                                    }}
                                                         src={"https://preprodback-istorio.monolithe.studio/files/" + lieu?.imgs[0]}
                                                         alt="vignette lieu"/>
                                                    <div style={{
                                                        textWrap: "wrap",
                                                        fontWeight: "bold",
                                                        marginTop: "5px",
                                                    }}>
                                                        {lieu.name}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }

                        </MapContainer>
                    }
                </div>
            </div>

            {/*/!*TODO : carousel partenaire*!/*/}

            {/*<div className='modale-infos-village'>*/}
            {/*    <InfoModal lieu="Place Vaudémont, 54000 Nancy" arret="Amerval" lignes={lignes4} />*/}
            {/*</div>*/}
        </div>
    )
}