import '../../Styles/village2.scss';
import '../../Styles/village.scss';
import village2 from "../../Assets/icons/villages/village2.png";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import pereFouettard from "../../Assets/pereFouettard.png";
import enfants from "../../Assets/enfants.png";
import VillagePresentation from "../../Components/VillagePresentation/villagePresentation";
import boisDesEnfants from "../../Assets/villages/boisDesEnfants.png";
import Article1 from "../../Components/Article1/article1";
import gare from "../../Assets/villages/gare.png";
import Carousel from "../../Components/Carousel/carousel";
import React, {useContext, useRef} from "react";
import Article2 from "../../Components/Article2/article2";
import premierMagasin from '../../Assets/villages/premierMagasin.png';
import excelsior from '../../Assets/villages/excelsior.png';
import Article3 from "../../Components/Article3/article3";
import huitres from '../../Assets/restauration/huitres.png';
import cookies from '../../Assets/restauration/cookies.png';
import chocolatChaud from '../../Assets/restauration/chocolatChaud.png'
import Restauration2 from "../../Components/Restauration2/restauration2";
import stars from '../../Assets/stars.png'
import arabesque from '../../Assets/arabesque.png'
import T2 from "../../Assets/icons/lignes/T2.png";
import T3 from "../../Assets/icons/lignes/T3.png";
import T4 from "../../Assets/icons/lignes/T4.png";
import corol from "../../Assets/icons/lignes/Corol.png";
import ligne11 from "../../Assets/icons/lignes/11.png";
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import L from "leaflet";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";
import {useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";


export default function Village2() {

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);
    const {typeNameToObject, lieux} = useContext(LieuxContext);


    const mapRef = useRef();

    const parcoursTitle = (
        <>
            Le Bois des <br />
            Trois petits Enfants
        </>
    );

    const intro= (
        <>
            C’était un soir d’hiver où la magie semblait s’être installée dans chaque recoin de Nancy. Des
            flocons de neige dansaient dans l’air, se posant délicatement sur les pavés glacés et les branches
            des sapins. La ville s’était parée de lumières, et la place Simone Veil brillait d’un éclat tout
            particulier. <br/>
            <br/>
            Au pied du grand carrousel aux chevaux de bois, trois enfants - Claudon, Philippe et Jacquot -
            scrutaient l’horizon avec des yeux remplis de rêves. Jacquot, emmitouflé dans son écharpe trop
            longue, avait une carte froissée entre les mains : un itinéraire secret des villages de Noël.
        </>
    )

    const introFullText = (
        <>
            « C’est là que l’aventure commence », murmura-t-il, le regard pétillant. Claudon, l’aîné et chef
            autoproclamé de l’expédition, prit son air sérieux et déclara :<br/>
            <br/>
            « Si on veut tout explorer avant minuit, on doit se dépêcher ! »<br/>
            <br/>
            La voix de Philippe, espiègle comme toujours, s’éleva :<br/>
            <br/>
            « Pas de problème, capitaine Claudon ! Mais t’es sûr que le Père Fouettard ne va pas nous
            suivre ? »<br/>
            <br/>
            Jacquot éclata de rire, son bonnet rouge tombant presque sur ses yeux. À cet instant précis, un
            vent glacé se leva, et dans l’ombre de la gare, une silhouette apparut, sinistre et maladroite.
            C’était le Père Fouettard. Drapé dans son manteau noir comme la nuit, le visage barbouillé de
            suie, il avançait d’un pas lourd, tenant fermement un vieux sac en toile. Il était venu directement
            du royaume des légendes, ayant pris un train tout droit sorti des contes anciens pour attraper les
            enfants « désobéissants ».<br/>
            <br/>
            Mais voilà que sa démarche sinistre tourna rapidement au ridicule : ses bottes usées glissèrent
            sur les pavés gelés de la place. C’est alors qu’après une chorégraphie digne des plus grands
            patineurs, il se retrouva assis par terre dans une pose improbable, les bras en croix et les jambes
            écartées. Un silence étrange s’installa, puis… un éternuement tonitruant fendit l’air :<br/>
            <br/>
            « ATCHAAAAA ! »<br/>
            <br/>
            La scène était si burlesque que les passants s’arrêtèrent pour rire, et même Claudon ne put
            retenir un éclat de voix :<br/>
            <br/>
            « Vite, les gars, on décolle avant qu’il se relève ! »<br/>
            <br/>
            Les trois enfants détalèrent comme des fusées, slalomant entre les sapins décorés et les vitrines
            illuminées. Le Père Fouettard se releva tant bien que mal, fulminant et s’époussetant. Mais ses
            bottes ne voulaient décidément pas coopérer : SCHLAK !, une nouvelle glissade, encore plus
            comique, l’envoya tournoyer comme un patineur artistique jusqu’à atterrir sur un banc.<br/>
            <br/>
            « Il est plus maladroit que méchant… » gloussa Jacquot en se cachant derrière le manège.<br/>
            <br/>
            Claudon et Philippe, essoufflés mais hilares, lui firent signe de les suivre. Pendant ce temps, le
            Père Fouettard, le dos douloureux et l’air penaud, maugréait dans sa barbe noire.
            « Maudits pavés, maudits enfants ! »<br/>
            <br/>
            Mais dans son regard, une petite étincelle de bienveillance semblait presque percer, comme s’il
            n’avait jamais vraiment été décidé à les attraper.<br/>
            <br/>
            Les enfants s’arrêtèrent quelques instants pour reprendre leur souffle. Devant eux, le carrousel
            tourbillonnait lentement dans une musique ancienne, ses lumières projetant des ombres dorées
            sur la neige fraîche. Claudon tendit la carte et murmura, comme dans les grands films d’aventure :
            « Et si c’était notre quête ? Explorer tous les villages de Noël avant minuit, et prouver qu’on peut
            échapper au Père Fouettard ? »<br/>
            « Et au boucher ! » ajouta Philippe d’un air effrayé.<br/>
            <br/>
            Jacquot frissonna mais sourit bravement :<br/>
            « Allez venez, c’est par là ! » pointant du doigt l’emplacement du prochain village<br/>
            <br/>
            À cet instant, un bruit sourd retentit. Le Père Fouettard venait d’éternuer à nouveau, si fort qu’une
            petite avalanche de neige tomba d’un sapin sur sa tête. Les enfants pouffèrent de rire et reprirent
            leur course, traversant la place comme des héros en plein début d’épopée.<br/>
            En quittant la place Simone Veil, la ville de Nancy brillait de mille feux derrière eux. La nuit venait
            de commencer, et l’aventure promettait d’être grandiose. Une chose était sûre : ce soir-là, la
            magie de la Saint Nicolas les attendait à chaque coin de rue.<br/>
            <br/>
            Pour les passants qui observaient la scène depuis les terrasses, une seule chose importait
            désormais : se réchauffer autour d’un chocolat chaud à l’Excelsior, savourant le spectacle
            amusant et magique que seuls les soirs d’hiver peuvent offrir.
        </>
    )

    const boisDesEnfantsDesc = "Fièrement dressé en face de la gare, il est comme une porte d’entrée sur les Fêtes de Saint Nicolas : cette année encore, le Village du Bois des 3 petits enfants met à l’honneur les miraculés de la légende ! Ambiance familiale en journée, avec diffusion de chants de Noël, manège et animations pour les petits ; le soir, guinguette hivernale pour les plus grands, pour manger, boire et guincher !"

    const gareNancy = (
        <>
            À la fin de l'année 1841, la France avait un net retard sur les autres pays industrialisés dans la construction de son réseau ferré. Une loi loi relative à l'établissement des grandes lignes de chemin de fer en France a alors été promulguée, donnant naissance au shéma appelé “Étoile de Legrand” (coucou la centralisation) et à la ligne “Paris-Nancy-Strasbourg”.<br/>
            <br/>
            Le choix du tracé n’était pas forcément évident pour les autorités, qui faisaient face à de nombreux enjeux stratégiques afin de ralier la frontière avec la Prusse. Mais les travaux, réalisés par tranches, ont bien démarrés en 1846 et la première desserte de Nancy est inaugurée le 10 juillet 1850.<br/>
            <br/>
            À cette époque, la gare est un simple baraquement en bois. Le bâtiment voyageurs, que vous avez sous les yeux, est terminé en 1856. « Rajeunie » en 1938-1939, après le fiasco d'un projet pharaonique de reconstruction totale en 1931 qui n'a jamais abouti, elle perd sa majestueuse marquise en 1960, lors de l’electrification de la ligne.<br/>
            <br/>
        </>
    )

    const premierMagasinDesc1 = "Au début du XX ème siècle,  Nancy connaît  une période de croissance économique avec l’annexion de l’Alsace-Moselle. Son statut de “Capitale de l’Est” entraîne l’arrivée de nombreux entrepreneurs, artisans et intellectuels dans la ville, créant alors une bourgeoisie dynamique propice à l’émergence d’un grand marché de consommation. Portée par le commerce, Nancy aspire à afficher son statut social à travers l’art, l’architecture et la consommation, notamment avec le mouvement Art Nouveau puis Art Déco. "

    const premierMagasinDesc2 = (
        <>
            À l’image du “Bon marché” à Paris, Antoine Corbin - homme d’affaire, père d’Eugène Corbin - imagine les
            Magasins Réunis, avec lesquels il offre une nouvelle gamme de produits dans le cadre d’un bâtiment
            spectaculaire. Publicité, catalogues de produits, mode adaptées aux saisonalités... Les Magasins Réunis
            deviennent rapidement le plus grand magasin de province avec plus de 12.000m2 de surface !<br/>
            <br/>
            Le bâtiment actuel, Art Déco, a remplacé le bâtiment originel Art Nouveau détruit par un incendi accidentel
            en 1916
        </>
    )

    const excelsiorTitle = (
        <>
        Art Nouveau <br/>et gastronomie
        </>
    )

    const artNouveauGastronomie = (
        <>
            En 1911, la brasserie Moreau et Cie ouvre à Nancy un nouvel établissement dans la tradition des grands cafés de la Belle époque. L’objectif était simple : faire de ce lieu une vitrine de luxe des produits proposés à la vente. <br/>
            <br/>
            Avec son hôtel de 50 chambres (aujourd’hui disparu), la brasserie Excelsior concentre alors tout le savoir-faire architectural nancéien. Majorelle, Grüber,  Daum... Les grands noms de l’École de Nancy ont ainsi participé à la mise en oeuvre du projet porté par les architectes Weissenburger et Mienville, qui proposait une approche sobre à l’exterieur (plus proche de l’école Viennoise) en contraste avec le chef d’œuvre de l’Art nouveau nancéien proposé à l’interieur.<br/>
            <br/>
            Dernier représentant des nombreux cafés de la ville dans les années folles, l’Excelsior - qui a failli disparaitre dans les années 70 en même temps que d’autres établissements emblématiques situés place de la garre - continu aujourd’hui de proposer une restauration en continue dans une athmosphère bouillonnante.<br/>
            Et seule la fresque redécouverte derrière un mirroir en 2022 rappelle avec une certaine nostalgie l’origine publicitaire du lieu.
        </>
    )


    const restauration2 = (
        <>
            Selon l’heure de la journée, on va se mettre au chaud dans l’atmosphère bouillonnante de l’Excelsior afin
            d’y déguster un excellent <strong>chocolat chaud</strong> ou, pour les amateurs, un incroyable plateau
            de <strong>fruits de mer</strong> écaillés sous nos yeux.<br/>
            <br/>
            Pour grignoter des spécialités sucrées locales, on pousse les portes de la boutique Lefevre-Lemoine. Fondée
            en 1840, cette biscuiterie familiale (à l’origine du fameux petit beurre “LU” de Nantes !) propose <strong>macarons,
            bergamottes,</strong> et autres <strong>visitandines.</strong>
        </>
    )

    const lignes2 = [
        {icon : T2, alt: 'ligne T2'},
        {icon : T3, alt: 'ligne T3'},
        {icon : T4, alt: 'ligne T4'},
        {icon : corol, alt: 'corol'},
        {icon : ligne11, alt: 'ligne 11'},
    ]

    const infoVillage2 = {
        dates: {
            opening: '22/11',
            closing: '05/01'
        },
        schedule: [
            {
                type:'allDays',
                hours : '11h - 20h'
            }
        ],
        nocturne: "Nocturne jusqu'à 22h30 les vendredis et samedis",
        closed : "Fermeture le lundi sauf pendant les vacances scolaires \n (du 21 décembre au 5 janvier)",
        adresse : "Place Simone Veil, 54000 Nancy",
        transport : "Gare Poirel"
    }

    return(
        <div className="etape village">
            {/*Header with audio player*/}
            <div className="etape-header">
                <div className="titre-village">
                    <img src={village2} alt=""/>
                    <h1 className="etape-header-title">{parcoursTitle}</h1>
                </div>

                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} text2={introFullText} image1={pereFouettard} image2={enfants}/>
            </div>

            {/*Village description*/}
            <div className="presentation-village">
                <VillagePresentation place='Place Simone Veil' image={boisDesEnfants} description={boisDesEnfantsDesc} information='Informations sur le Village' lieu={infoVillage2} lignes={lignes2} />
            </div>

            {/*Ventre de Nancy*/}
            <div className='ventre-nancy'>
                <Article1 subtitle="La gare de Nancy" title="Le cheval de fer entre dans Nancy" description={gareNancy}
                          image={gare} mention=""/>
            </div>

            {/*premier grand magasin*/}
            <div className='premier-magasin'>
                <Article2 subtitle="Les magasins Réunis" title="Le premier grand-magasin"
                          description1={premierMagasinDesc1} description2={premierMagasinDesc2} image={premierMagasin}
                          mention=""/>
            </div>

            {/*excelsior*/}
            <div className='ventre-nancy'>
                <Article3 subtitle="L'Excelsior'" title={excelsiorTitle} description={artNouveauGastronomie} image={excelsior}
                          mention=""/>
            </div>

            {/*Restauration*/}
            <div className='village1-restauration'>
                <Restauration2 image1={huitres} image2={cookies} image3={chocolatChaud} description={restauration2}/>
            </div>

            {/*petites etoiles*/}
            <div className="stars">
                <img src={stars} alt=""/>
            </div>

            {/*Arabesque*/}
            <div className="arabesque">
                <img src={arabesque} alt=""/>
            </div>

            {/*Evenements*/}
            <div className="village1-carousel">
                <h3>Les événements à proximité</h3>
                <h2>Ne pas rater:</h2>
                <Carousel/>
            </div>

            {/*/!*TODO : patrimoine proche*!/*/}

            <div className="village-carte">
                <div className="village-carte-titre">À (re)découvrir</div>
                <div className="village-carte-soustitre">Le patrimoine proche</div>
                <div className="village-carte-carte">
                    {
                        lieux.length > 0 &&
                        <MapContainer ref={mapRef} center={etapes.find((etape) => etape.id === etapeCourante).coords}
                                      zoom={20} scrollWheelZoom={true} style={{
                            width: '100%',
                            height: '100%',
                        }} whenReady={(e) => {
                            e.target.on('popupopen', function (e) {
                                if (e.popup.options.id.length === 1) {
                                    setEtapeCourante(e.popup.options.id);
                                }
                                e.target.closePopup();
                                e.popup.options.id === "1" ?
                                    navigate("/village1") :
                                    e.popup.options.id === "2" ?
                                        navigate("/village2") :
                                        e.popup.options.id === "3" ?
                                            navigate("/village3") :
                                            e.popup.options.id === "4" ?
                                                navigate("/village4") :
                                                e.popup.options.id === "5" ?
                                                    navigate("/village5") :
                                                    e.popup.options.id === "6" ?
                                                        navigate("/village6") :
                                                        e.popup.options.id === "7" ?
                                                            navigate("/village7") :
                                                            e.popup.options.id === "8" ?
                                                                navigate("/village8") :
                                                                navigate("/lieu/" + e.popup.options.id);
                            });
                        }}>

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                            />

                            {
                                etapes.map((etape) => {
                                    return (
                                        <Marker position={etape.coords} icon={L.icon({
                                            iconUrl: etape.id === "1" ? v1 :
                                                etape.id === "2" ? v2 :
                                                    etape.id === "3" ? v3 :
                                                        etape.id === "4" ? v4 :
                                                            etape.id === "5" ? v5 :
                                                                etape.id === "6" ? v6 :
                                                                    etape.id === "7" ? v7 :
                                                                        v8,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={etape.id}>
                                                {etape.titre}
                                            </Popup>
                                            <Tooltip direction="top">
                                                {etape.titre}
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                lieux?.map((lieu) => {
                                    return (
                                        <Marker position={lieu?.coords} icon={L.icon({
                                            iconUrl: typeNameToObject(lieu.type).icon,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={lieu.name}>
                                                {lieu.name}
                                            </Popup>
                                            <Tooltip direction="top">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "150px",
                                                }}>
                                                    <img style={{
                                                        width: "150px",
                                                        height: "112px",
                                                        objectFit: "cover",
                                                    }}
                                                         src={"https://preprodback-istorio.monolithe.studio/files/" + lieu?.imgs[0]}
                                                         alt="vignette lieu"/>
                                                    <div style={{
                                                        textWrap: "wrap",
                                                        fontWeight: "bold",
                                                        marginTop: "5px",
                                                    }}>
                                                        {lieu.name}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }

                        </MapContainer>
                    }
                </div>
            </div>

            {/*/!*TODO : carousel partenaire*!/*/}

            {/*<div className='modale-infos-village'>*/}
            {/*    <InfoModal lieu="Place Simone Veil, 54000 Nancy" arret="Gare Poirel" lignes={lignes2} />*/}
            {/*</div>*/}
        </div>
    )
}