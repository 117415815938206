import '../../Styles/footer.scss'
import {Link} from "react-router-dom";


export default function Footer(){
    return(
        <div className="footer">
            <h2 className="footer-title">Liens utiles</h2>
            <div className="footer-links">
                <Link to="https://saint-nicolas.nancy.fr/accueil" target='_blank' className="footer-links-link"> Site officiel des fêtes de la Saint-Nicolas</Link>
                <Link to="https://destination-nancy.com/" target='_blank' className="footer-links-link"> Site officiel de Destination Nancy</Link>
            </div>
            <Link to="" target='_blank' className="footer-link-istorio">Istor.io v0.1</Link>
        </div>
    )
}