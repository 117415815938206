import '../../Styles/article1.scss'
import etoile from '../../Assets/etoile.png'


export default function Article1({subtitle, title, description, image,mention}) {


    return(
        <div className='article1'>
            <div className='article1-titles'>
                <h3>{subtitle}</h3>
                <h2>{title}</h2>
            </div>

            <div className='article1-content'>
                <div className='article1-content-description'>
                    <p className='article1-content-description-text'>{description}</p>
                    <p className='article1-content-description-mention'>{mention}</p>
                </div>
                <div className='article1-content-image'>
                    <img src={image} alt="" className='article1-content-image-picture'/>
                    <img src={etoile} alt="" className='article1-content-image-star'/>
                </div>

            </div>
        </div>
    )
}