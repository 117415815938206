import '../../Styles/article3.scss'
import etoile from "../../Assets/etoile.png";


export default function Article3({subtitle, title, description, image,mention}){
    return(
        <div className='article3'>
            <div className='article3-titles'>
                <h3>{subtitle}</h3>
                <h2>{title}</h2>
            </div>

            <div className='article3-content'>
                <div className='article3-content-description'>
                    <p className='article3-content-description-text'>{description}</p>
                    <p className='article3-content-description-mention'>{mention}</p>
                </div>
                <div className='article3-content-image'>
                    <img src={image} alt="" className='article3-content-image-picture'/>
                </div>

            </div>
        </div>
    )
}