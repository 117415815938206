import '../../Styles/infoModal.scss'
import adresse from '../../Assets/icons/adresse.png'
import heure from '../../Assets/icons/heure.png'
import parking from '../../Assets/icons/parking.png'
import parking_velo from '../../Assets/icons/parking_velo.png'
import transport from '../../Assets/icons/transport.png'
import fleche from '../../Assets/icons/fleche.png'
import {Fragment} from "react";


export default function InfoModal({lieu, lignes}) {
    const OpeningHours = (schedule) => {
        if (!schedule || !Array.isArray(schedule)) return null;

        return (
            <ul className="infomodal-rubrique-infos" style={{margin:'0', padding:'0'}}>
                {schedule.map((entry, index) => {
                    const formatHours = (hours, useArrow = true) => {
                        if (!hours) return "Horaires non disponibles";
                        const [start, end] = hours.split(" - ");
                        return useArrow
                            ? (
                                <>
                                    {start} <img src={fleche} alt="" style={{ width: '20px', objectFit: 'scale-down', padding: '0 2% ' }} /> {end}
                                </>
                            )
                            : `${start} à ${end}`;
                    };

                    if (entry.type === "single") {
                        return (
                            <li key={index} >
                                {entry.days.join(", ")} : {formatHours(entry.hours)}
                            </li>
                        );
                    } else if (entry.type === "range") {
                        return (
                            <li key={index} >
                                Du {entry.days.from} au {entry.days.to} : {formatHours(entry.hours)}
                            </li>
                        );
                    } else if (entry.type === "allDays"){
                        return (
                            <li key={index}>
                                Tous les jours de {formatHours(entry.hours, false)}
                            </li>
                        )
                    } else if (entry.days &&
                        entry.hours) {
                        return (
                            <li key={index}>
                            {entry.days.join(", ")} : {formatHours(entry.hours)}
                            </li>
                        );
                    } else if (entry.description && entry.hours) {
                        return (
                            <li key={index} >
                                <em>{entry.description}</em> : {formatHours(entry.hours)}
                            </li>
                        );
                    } else if (entry.closed) {
                        return (
                            <li key={index}>
                                Fermé : {entry.closed.join(", ")}
                            </li>
                        );
                    }
                    return null;
                })}
            </ul>
        );
    };



    return(
        <div className='infomodal'>
            <h2>Les informations pratiques du Village</h2>
            {/*Horaires*/}
            <div className="infomodal-rubrique">
                <img src={heure} alt="horaires ouverture" className="infomodal-rubrique-icone"></img>
                <div className="infomodal-rubrique-infos">
                    <div className="infomodal-rubriques-infos-dates" >
                        <p>{lieu.dates.opening}</p>
                        <img src={fleche} alt=""/>
                        <p>{lieu.dates.closing}</p>
                    </div>
                    {OpeningHours(lieu?.schedule)}
                    <p className="infomodal-rubrique-infos">{lieu.nocturne}</p>
                    <p className="infomodal-rubrique-infos" style={{lineHeight:'2'}}>{lieu.closed.split("\n").map((line, index) => (
                        <Fragment key={index}>
                            {line}
                            <br />
                        </Fragment>))}</p>
                </div>
            </div>

            {/*Adresse*/}
            <div className="infomodal-rubrique">
                <img src={adresse} alt="adresse" className="infomodal-rubrique-icone"></img>
                <p className="infomodal-rubrique-infos">{lieu.adresse}</p>
            </div>

            {/*Parkings*/}
            <div className="infomodal-rubrique">
                <img src={parking} alt="parking voiture" className="infomodal-rubrique-icone"></img>
                <p className="infomodal-rubrique-infos">Parking voiture (payant)</p>
            </div>
            <div className="infomodal-rubrique">
                <img src={parking_velo} alt="parking vélo" className="infomodal-rubrique-icone"></img>
                <p className="infomodal-rubrique-infos">Parking vélo (sur la voie publique)</p>
            </div>

            {/*Transports*/}
            <div className="infomodal-rubrique-transports">
                <div className="infomodal-rubrique">
                    <img src={transport} alt="parking vélo" className="infomodal-rubrique-icone"></img>
                    <p className="infomodal-rubrique-infos">{lieu.transport}</p>
                </div>

                <div className="infomodal-rubrique">
                    <img src={transport} alt="parking vélo" className="infomodal-rubrique-icone" style={{opacity:'0'}}></img>
                    <div className="infomodal-rubrique-lignes">
                    {lignes.map((ligne,
                                     index) => {
                            return (
                                <img src={ligne.icon} alt={ligne.alt} key={index}/>
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    )
}