import '../../Styles/village4.scss';
import '../../Styles/village.scss';
import village4 from "../../Assets/icons/villages/village4.png";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import gobbi from '../../Assets/gobbi.png';
import enfants from "../../Assets/enfants.png";
import VillagePresentation from "../../Components/VillagePresentation/villagePresentation";
import vaudemont from "../../Assets/villages/vaudemont.png";
import Article1 from "../../Components/Article1/article1";
import vieilleVille from '../../Assets/villages/vieilleVille.png';
import Article2 from "../../Components/Article2/article2";
import huitres from '../../Assets/restauration/huitres.png';
import soupe from '../../Assets/restauration/soupe.png';
import vinChaud from '../../Assets/restauration/vinChaud.png';
import arabesque from "../../Assets/arabesque.png";
import Carousel from "../../Components/Carousel/carousel";
import monstres from '../../Assets/villages/monstres.png';
import React, {
    useContext,
    useRef,
} from "react";
import Restauration4 from "../../Components/Restauration4/restauration4";
import ligne12 from "../../Assets/icons/lignes/12.png";
import ligne13 from "../../Assets/icons/lignes/13.png";
import ligne16 from "../../Assets/icons/lignes/16.png";
import citadine from "../../Assets/icons/lignes/citadine.png";
import resago from "../../Assets/icons/lignes/resago.png";
import flexo from "../../Assets/icons/lignes/flexo.png";
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import L from "leaflet";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";
import {useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";

export default function Village4() {

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);
    const {typeNameToObject, lieux} = useContext(LieuxContext);
    const mapRef = useRef();

    const parcoursTitle = (
        <>
            Le Hameau gourmand <br />
            de Saint-Nicolas
        </>
    );

    const intro= (
        <>
            Dans le brouhaha de la fête, les enfants arrivent sur la place Vaudémont. <br/>
            Ici, le passé semble danser avec le présent. Les gravures fantastiques de Jacques Callot prennent vie, et des personnages hauts en couleur, comme les gobbi, se joignent aux festivités.
        </>
    )

    const vaudemontDesc=(
        <>
            Hautement prisé pour les afterworks, le Hameau gourmand de Saint-Nicolas est depuis toujours porté par l’association “Le grenier de Callot”. <br/>
            Que ce soit pour boire un verre, manger sur le pouce ou prendre le temps de savourer, vous êtes au bon endroit ! Les animations en fin de semaine vous permettent de découvrir toute une gamme de produits locaux.
        </>
    )

    const monstresCompagnie = (
        <>
            Levez les yeux vers la fontaine qui orne la Place et faites connaissance avec une des plus grandes figures culturelles de la Ville de Nancy. Jacques Callot est un dessinateur et graveur né en 1592. C’est le onzième enfant de Jean Callot, premier héraut d’armée de Lorraine.  <br/>
            <br/>
            Jeune, il dessinait et colorait des armoiries devant les yeux de sa famille, ce qui a introduit son attirance pour les arts. A seulement douze ans, il part pour l’Italie avec une troupe de bohémiens et rejoins Florence où il paufinera son univers... <br/>
            De ses souvenirs pittoresques, il s’inspire des bouffons de la cour du grand duc Cosme II de Médicis. Ces dessins, représentant des petits personnages farceurs et espiègles, montrent le talent de Callot pour la caricature et le goût pour les scènes grotesques. <br/>
            <br/>
            Il revint à quinze ans de son périple et entame alors une carrière prolifique : on compte plus de 1600 planches et oeuvres, caractérisées par son style net et la profondeur de son encrage dans ses créations, qui sont aujourd’hui exposée aux quatres coins du monde.

        </>
    )

    const vieilleVilleDesc1 = (
        <>
            "Eh oui, Nancy, ça n'est pas que la Place Stanislas !"<br/>
            <br/>
            Suivez les pas d’Alexandra Pierré-Caps, notre historienne en cheffe, qui nous fait découvrir la Ville
            Vieille avec une vidéo à la fois décalée et gourmande.<br/>
            <br/>
            Visitez l’interieux de la Porte de la Craffe, découvrez les secrets de la Chapelle des Cordeliers,
            bénéficiez d’une vue inédite depuis l’Arc Héré...
        </>
    )

    const restauration4 = (
        <>
            S’arrêter pour boire un <strong>vin chaud</strong>, mais surtout une <strong>mirabelle chaude</strong> ou
            une <strong>bière chaude</strong> afin d’être un peu plus dans la couleur locale. (Mais avec modération,
            hein).<br/>
            <br/>
            S’attabler et déguster une bonne <strong>soupe chaude</strong>, des <strong>escargots</strong>,
            des <strong>huitres</strong> ou tout autre petits plats préparés par les commercants locaux, qui animent
            avec succès ce petit Hameau particulièrement convivial.
        </>
    )

    const lignes4 = [
        {icon : ligne12, alt: 'ligne 12'},
        {icon : ligne13, alt: 'ligne 13'},
        {icon : ligne16, alt: 'ligne 16'},
        {icon : citadine, alt: 'citadine'},
        {icon : resago, alt: 'résa go'},
        {icon : flexo, alt: 'flexo soirée'},
    ]

    const infoVillage4 = {
        dates: {
            opening: '23/11',
            closing: '05/01'
        },
        schedule: [
            {
                type:'single',
                days:['Mercredi et jeudi'],
                hours : '16h - 22h'
            },
            {
                type:'single',
                days:['Vendredi et samedi'],
                hours : '11h - 23h'
            },
            {
                type:'single',
                days:['Dimanche'],
                hours : '11h - 21h'
            }
        ],
        closed : "Fermeture les lundis et mardis",
        adresse : "Place Vaudémont, 54000 Nancy",
        transport : "Amerval"
    }

    return(
        <div className="village4 village">
            {/*Header with audio player*/}
            <div className="village4-header">
                <div className="titre-village">
                    <img src={village4} alt=""/>
                    <h1 className="etape-header-title">{parcoursTitle}</h1>
                </div>

                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} link={"/"} image1={gobbi} image2={enfants}/>
            </div>

            {/*Village description*/}
            <div className="presentation-village">
                <VillagePresentation place='Place Vaudémont' image={vaudemont} description={vaudemontDesc}
                                     information='Informations sur le Hameau' lieu={infoVillage4} lignes={lignes4}/>
            </div>

            {/*musée des beaux arts*/}
            <div className='ventre-nancy'>
                <Article1 subtitle="Musée des Beaux-Arts" title="Monstres & Compagnie" description={monstresCompagnie}
                          image={monstres} mention=""/>
            </div>

            {/*vieille ville*/}
            <div className='villeVille' >

                <Article2 subtitle="La Ville Vieille" title="Au coeur de l'histoire" description3={vieilleVilleDesc1}
                          image={vieilleVille} mention="" />
            </div>



            {/*Restauration*/}
            <div className='village1-restauration'>
                <Restauration4 image1={huitres} image2={soupe} image3={vinChaud} description={restauration4}/>
            </div>

            {/*Arabesque*/}
            <div className="arabesque">
                <img src={arabesque} alt=""/>
            </div>

            {/*Evenements*/}
            <div className="village1-carousel">
                <h3>Les événements à proximité</h3>
                <h2>Ne pas rater:</h2>
                <Carousel/>
            </div>

            {/*/!*TODO : patrimoine proche*!/*/}

            <div className="village-carte">
                <div className="village-carte-titre">À (re)découvrir</div>
                <div className="village-carte-soustitre">Le patrimoine proche</div>
                <div className="village-carte-carte">
                    {
                        lieux.length >
                        0 &&
                        <MapContainer ref={mapRef} center={etapes.find((etape) => etape.id ===
                            etapeCourante).coords}
                                      zoom={20} scrollWheelZoom={true} style={{
                            width: '100%',
                            height: '100%',
                        }} whenReady={(e) => {
                            e.target.on('popupopen',
                                function (e) {
                                    if (e.popup.options.id.length ===
                                        1) {
                                        setEtapeCourante(e.popup.options.id);
                                    }
                                    e.target.closePopup();
                                    e.popup.options.id ===
                                    "1" ?
                                        navigate("/village1") :
                                        e.popup.options.id ===
                                        "2" ?
                                            navigate("/village2") :
                                            e.popup.options.id ===
                                            "3" ?
                                                navigate("/village3") :
                                                e.popup.options.id ===
                                                "4" ?
                                                    navigate("/village4") :
                                                    e.popup.options.id ===
                                                    "5" ?
                                                        navigate("/village5") :
                                                        e.popup.options.id ===
                                                        "6" ?
                                                            navigate("/village6") :
                                                            e.popup.options.id ===
                                                            "7" ?
                                                                navigate("/village7") :
                                                                e.popup.options.id ===
                                                                "8" ?
                                                                    navigate("/village8") :
                                                                    navigate("/lieu/" +
                                                                        e.popup.options.id);
                                });
                        }}>

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                            />

                            {
                                etapes.map((etape) => {
                                    return (
                                        <Marker position={etape.coords} icon={L.icon({
                                            iconUrl: etape.id ===
                                            "1" ?
                                                v1 :
                                                etape.id ===
                                                "2" ?
                                                    v2 :
                                                    etape.id ===
                                                    "3" ?
                                                        v3 :
                                                        etape.id ===
                                                        "4" ?
                                                            v4 :
                                                            etape.id ===
                                                            "5" ?
                                                                v5 :
                                                                etape.id ===
                                                                "6" ?
                                                                    v6 :
                                                                    etape.id ===
                                                                    "7" ?
                                                                        v7 :
                                                                        v8,
                                            iconSize: [30,
                                                30],
                                            iconAnchor: [15,
                                                0],
                                            popupAnchor: [0,
                                                -30],
                                        })}>
                                            <Popup id={etape.id}>
                                                {etape.titre}
                                            </Popup>
                                            <Tooltip direction="top">
                                                {etape.titre}
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                lieux?.map((lieu) => {
                                    return (
                                        <Marker position={lieu?.coords} icon={L.icon({
                                            iconUrl: typeNameToObject(lieu.type).icon,
                                            iconSize: [30,
                                                30],
                                            iconAnchor: [15,
                                                0],
                                            popupAnchor: [0,
                                                -30],
                                        })}>
                                            <Popup id={lieu.name}>
                                                {lieu.name}
                                            </Popup>
                                            <Tooltip direction="top">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "150px",
                                                }}>
                                                    <img style={{
                                                        width: "150px",
                                                        height: "112px",
                                                        objectFit: "cover",
                                                    }}
                                                         src={"https://preprodback-istorio.monolithe.studio/files/" +
                                                             lieu?.imgs[0]}
                                                         alt="vignette lieu"/>
                                                    <div style={{
                                                        textWrap: "wrap",
                                                        fontWeight: "bold",
                                                        marginTop: "5px",
                                                    }}>
                                                        {lieu.name}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }

                        </MapContainer>
                    }
                </div>
            </div>

            {/*/!*TODO : carousel partenaire*!/*/}

        </div>
    )
}