import '../../Styles/villagePresentation.scss'
import etoile from '../../Assets/etoile.png';
import React, {useState} from "react";
import InfoModal from "../InfoModal/infoModal";


export default function VillagePresentation({place, image,information, description,lieu, lignes}) {

    const [isOpen, setIsOpen] = useState(false)

    const handleDisplay=() =>{
        setIsOpen(!isOpen)
    }

    // {lignes &&
    // console.log("lignes",lignes)}


    return(
        <div className="villageDescription">
            <div className="villageDescription-titles">
                <h3>{place}</h3>
                <h2>{information}</h2>
            </div>

            <div className="villageDescription-presentation">
                <img src={etoile} alt="" className='villageDescription-etoile1'/>
                <img src={image} alt='' className="villageDescription-image"/>
                <div className='villageDescription-infos'>
                    <img src={etoile} alt="" className='villageDescription-etoile2'/>
                    <p className='villageDescription-description'>{description}</p>
                    <p className='villageDescription-horaires' onClick={handleDisplay}>Horaires et accès</p>
                </div>
            </div>

            <div className='modale-infos-village' style={{
                display: isOpen ?
                    'block' :
                    'none'
            }}>
                <InfoModal lieu={lieu}
                           lignes={lignes}/>
                <p className='modale-infos-village-cross' onClick={handleDisplay}>X</p>
            </div>

            {/* Overlay */}
            {isOpen && (
                <div
                    className="overlay"
                    onClick={handleDisplay}
                ></div>
            )}
        </div>
    )
}