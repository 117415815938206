import '../../Styles/village5.scss'
import '../../Styles/village6.scss'
import '../../Styles/village.scss'
import village6 from "../../Assets/icons/villages/village6.png";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import fouettardBoucher from "../../Assets/fouettardBoucher.png";
import enfants from "../../Assets/enfants.png";
import VillagePresentation from "../../Components/VillagePresentation/villagePresentation";
import Article1 from "../../Components/Article1/article1";
import fromage from '../../Assets/restauration/fromage.png';
import cookies2 from '../../Assets/restauration/cookies2.png';
import stepvre from '../../Assets/restauration/stepvre.png';
import arabesque from "../../Assets/arabesque.png";
import Carousel from "../../Components/Carousel/carousel";
import saintEpvre from '../../Assets/villages/saintEpvre.png';
import fantastique from '../../Assets/villages/fantastique.png'
import React, {useContext, useRef} from "react";
import Article3 from "../../Components/Article3/article3";
import caveau from '../../Assets/villages/caveau.png'
import Restauration6 from "../../Components/Restauration6/restauration6";
import ligne12 from "../../Assets/icons/lignes/12.png";
import ligne13 from "../../Assets/icons/lignes/13.png";
import ligne16 from "../../Assets/icons/lignes/16.png";
import citadine from "../../Assets/icons/lignes/citadine.png";
import resago from "../../Assets/icons/lignes/resago.png";
import flexo from "../../Assets/icons/lignes/flexo.png";
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import L from "leaflet";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";
import {useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";

export default function Village6(){

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);
    const {typeNameToObject, lieux} = useContext(LieuxContext);


    const mapRef = useRef();

    const parcoursTitle = (
        <>
            Le Hameau <br />
            des bons vivants
        </>
    );

    const intro= (
        <>
            Bons petits plats, bonne ambiance,   <br/>
        </>
    )

    const animauxFantastiques = (
        <>
            Prenez le temps d’admirer la facade du palais des ducs de Lorraine. Elle ne fait qu’intriguer ses visiteurs par ses sculptures surprenantes et aux anecdotes qui s’y rattachent ! <br/>
            <br/>
            Comme la porte “Masco”, la petite porte de gauche lorsqu’on fait face à la magnifique porterie du Palais. Son nom est celui d’un ours apprivoisé par Leopold (une tradition du duché en mémoire des services que le canton de Berne avait rendu au Lorrains dans la guerre contre le duc de Bourgogne) <br/>
            <br/>
            Ou encore, juste au dessus, la petite sculpture d’un singe portant un habit de moine. Ce curieux animal serait l’œuvre des sculpteurs du Palais qui, excédés par les remarques désagréables que pouvaient leur faire les moines de l’ordre cordeliers - qui venaient observer le chantier en voisins - ont décidés de graver dans la pierre leurs états d’âme.<br/>
            (On peut ajouter à ce bestiaire les Alérions, ces aigles sans bec, symboles de Nancy - et du duché de Lorraine - et qui, s’ils ne sont pas sculptés sur la facade, sont présents sur le drapeau or et rouge flottant au dessus du Palais).
        </>
    )

    const tombeau = (
        <>
            Peu de gens osent pousser ses lourdes portes en bois. Pourtant, l'église des Cordeliers est un haut lieu de l'histoire, aussi bien lorraine qu’européenne !<br/>
            <br/>
            Véritable “Saint-Denis” lorrain, nécropole des Ducs de Lorraine dont elle fut la capitale, elle représente surtout les origines locales de la famille de Habsbourg-Lorraine qui a régné sur l’Autriche-Hongrie pendant près de deux siècles entre 1736 (mariage de Marie-Thérèse de Habsbourg avec François III, dernier duc de Lorraine et de Bar) jusqu'en 1918, date de la déposition de l'archiduc Charles 1er de Habsbourg-Lorraine, dernier empereur régnant de la dynastie.<br/>
            <br/>
            Et pour dire à quel point Nancy reste dans le coeur des descendants de cette grande famille impériale et royale, c'est ici que l'archiduc Otto de Habsbourg-Lorraine, fils du dernier empereur d'Autriche, s'est marié en 1951, tout comme son Altesse Impériale et Royale l’Archiduc Christoph d’Autriche en 2012.<br/>
            <br/>
            Les Habsbourg-Lorraine viennent encore se recueillir sur le tombeau (privé et inaccessible au public) de leurs ancêtres, tout comme l'ont fait avant eux Marie-Antoinette en mai 1770, qui se rend à Paris pour épouser le futur Louis XVI, ou l'empereur Joseph II en 1777...<br/>
            <br/>
        </>
    )

    const restauration6 = (
        <>
            Sur le Hameau, on savoure les excellents <strong>mets au fromage</strong> préparés par Caseus, fromagerie
            indépendante située un peu plus haut dans la Grande-Rue.<br/>
            <br/>
            Pour les becs sucrés, on passe la porte de chez Adam afin de découvrir les <strong>macarons</strong>, ou on
            se laisse tenter par un <strong>Saint-Epvre</strong>,
            spécialité à base de crème au beurre, d’amandes et de bergamote (entre autres).
        </>
    )

    const saintEpvreDesc='Bons petits plats, bonne ambiance et bonne compagnie : bienvenue au Hameau des bons vivants ! Sur la place Saint-Epvre sont installés par les gérants de “Le petit Bodiem” et “Speakeasy” un chalet de restauration, un autre de boissons en tout genre et un chouette espace cosy et confortable pour chiller et passer... un bon moment, évidemment !'

    const lignes6 = [
        {icon : ligne12, alt: 'ligne 12'},
        {icon : ligne13, alt: 'ligne 13'},
        {icon : ligne16, alt: 'ligne 16'},
        {icon : citadine, alt: 'citadine'},
        {icon : resago, alt: 'résa go'},
        {icon : flexo, alt: 'flexo soirée'},
    ]

    const infoVillage6 = {
        dates: {
            opening: '23/11',
            closing: '05/01'
        },
        schedule: [
            {
                type:'single',
                days:['Mercredi et jeudi'],
                hours : '16h - 22h'
            },
            {
                type:'single',
                days:['Vendredi et samedi'],
                hours : '11h - 23h'
            },
            {
                type:'single',
                days:['Dimanche'],
                hours : '11h - 21h'
            }
        ],
        closed : "Fermeture les lundis et mardis",
        adresse : "Place Vaudémont, 54000 Nancy",
        transport : "Amerval"
    }

    return(
        <div className="village6 village">
            {/*Header with audio player*/}
            <div className="village6-header">
                <div className="titre-village">
                    <img src={village6} alt=""/>
                    <h1 className="etape-header-title">{parcoursTitle}</h1>
                </div>

                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} link={"/"} image1={fouettardBoucher} image2={enfants}/>
            </div>

            {/*Village description*/}
            <div className="presentation-village">
                <VillagePresentation place='Place Saint-Epvre' image={saintEpvre} description={saintEpvreDesc}
                                     information='Informations sur le Hameau' lieu={infoVillage6} lignes={lignes6}/>
            </div>

            {/*jardin de la citadelle*/}
            <div className='ventre-nancy'>
                <Article1 subtitle="Le palais Ducal" title="Les animaux fantastiques" description={animauxFantastiques}
                          image={fantastique} mention=""/>
            </div>

            {/*vieille ville*/}
            <div className='villeVille'>
                <Article3 subtitle="Chapelle des Cordeliers" title="Un tombeau pas comme les autres"
                          description={tombeau} image={caveau} mention=""/>
            </div>


            {/*Restauration*/}
            <div className='village1-restauration'>
                <Restauration6 image1={fromage} image2={cookies2} image3={stepvre} description={restauration6}/>
            </div>

            {/*Arabesque*/}
            <div className="arabesque">
                <img src={arabesque} alt=""/>
            </div>

            {/*Evenements*/}
            <div className="village1-carousel">
                <h3>Les événements à proximité</h3>
                <h2>Ne pas rater:</h2>
                <Carousel/>
            </div>

            {/*/!*TODO : patrimoine proche*!/*/}

            <div className="village-carte">
                <div className="village-carte-titre">À (re)découvrir</div>
                <div className="village-carte-soustitre">Le patrimoine proche</div>
                <div className="village-carte-carte">
                    {
                        lieux.length > 0 &&
                        <MapContainer ref={mapRef} center={etapes.find((etape) => etape.id === etapeCourante).coords}
                                      zoom={20} scrollWheelZoom={true} style={{
                            width: '100%',
                            height: '100%',
                        }} whenReady={(e) => {
                            e.target.on('popupopen', function (e) {
                                if (e.popup.options.id.length === 1) {
                                    setEtapeCourante(e.popup.options.id);
                                }
                                e.target.closePopup();
                                e.popup.options.id === "1" ?
                                    navigate("/village1") :
                                    e.popup.options.id === "2" ?
                                        navigate("/village2") :
                                        e.popup.options.id === "3" ?
                                            navigate("/village3") :
                                            e.popup.options.id === "4" ?
                                                navigate("/village4") :
                                                e.popup.options.id === "5" ?
                                                    navigate("/village5") :
                                                    e.popup.options.id === "6" ?
                                                        navigate("/village6") :
                                                        e.popup.options.id === "7" ?
                                                            navigate("/village7") :
                                                            e.popup.options.id === "8" ?
                                                                navigate("/village8") :
                                                                navigate("/lieu/" + e.popup.options.id);
                            });
                        }}>

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                            />

                            {
                                etapes.map((etape) => {
                                    return (
                                        <Marker position={etape.coords} icon={L.icon({
                                            iconUrl: etape.id === "1" ? v1 :
                                                etape.id === "2" ? v2 :
                                                    etape.id === "3" ? v3 :
                                                        etape.id === "4" ? v4 :
                                                            etape.id === "5" ? v5 :
                                                                etape.id === "6" ? v6 :
                                                                    etape.id === "7" ? v7 :
                                                                        v8,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={etape.id}>
                                                {etape.titre}
                                            </Popup>
                                            <Tooltip direction="top">
                                                {etape.titre}
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                lieux?.map((lieu) => {
                                    return (
                                        <Marker position={lieu?.coords} icon={L.icon({
                                            iconUrl: typeNameToObject(lieu.type).icon,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={lieu.name}>
                                                {lieu.name}
                                            </Popup>
                                            <Tooltip direction="top">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "150px",
                                                }}>
                                                    <img style={{
                                                        width: "150px",
                                                        height: "112px",
                                                        objectFit: "cover",
                                                    }}
                                                         src={"https://preprodback-istorio.monolithe.studio/files/" + lieu?.imgs[0]}
                                                         alt="vignette lieu"/>
                                                    <div style={{
                                                        textWrap: "wrap",
                                                        fontWeight: "bold",
                                                        marginTop: "5px",
                                                    }}>
                                                        {lieu.name}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }

                        </MapContainer>
                    }
                </div>
            </div>

            {/*/!*TODO : carousel partenaire*!/*/}

            {/*<div className='modale-infos-village'>*/}
            {/*    <InfoModal lieu="Place Vaudémont, 54000 Nancy" arret="Amerval" lignes={lignes4} />*/}
            {/*</div>*/}
        </div>
    )
}