import '../../Styles/village1.scss';
import '../../Styles/village.scss';
import React, {useContext, useRef} from "react";

// Components
import AudioReader from "../../Components/AudioReader/audioReader";
import VillagePresentation from "../../Components/VillagePresentation/villagePresentation";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import Article1 from "../../Components/Article1/article1";

// Audio
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";

// images
import ligneA from "../../Assets/icons/lignes/A.png";
import T2 from "../../Assets/icons/lignes/T2.png";
import T3 from "../../Assets/icons/lignes/T3.png";
import T4 from "../../Assets/icons/lignes/T4.png";
import corol from "../../Assets/icons/lignes/Corol.png";
import ligne11 from "../../Assets/icons/lignes/11.png";
import ligne12 from "../../Assets/icons/lignes/12.png";
import ligne13 from "../../Assets/icons/lignes/13.png";
import ligne15 from "../../Assets/icons/lignes/15.png";
import ligne16 from "../../Assets/icons/lignes/16.png";
import citadine from "../../Assets/icons/lignes/citadine.png";
import lune from "../../Assets/icons/lignes/lune.png";
import resago from "../../Assets/icons/lignes/resago.png";
import flexo from "../../Assets/icons/lignes/flexo.png";
import boucher from '../../Assets/boucher.png';
import enfants from '../../Assets/enfants.png';
import village1 from '../../Assets/icons/villages/village1.png'
import placeCharles3 from '../../Assets/villages/placeCharles3.png'
import marcheCouvert from '../../Assets/villages/marcheCouvert.png'
import cake from '../../Assets/restauration/cake.png'
import saucisse from '../../Assets/restauration/saucisse.png'
import carotteOrange from '../../Assets/restauration/carotteOrange.png'
import Restauration from "../../Components/Restauration/restauration";
import stars from '../../Assets/stars.png'
import arabesque from '../../Assets/arabesque.png'
import Carousel from "../../Components/Carousel/carousel";
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import L from "leaflet";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";
import {useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";


export default function Village1() {

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);
    const {typeNameToObject, lieux} = useContext(LieuxContext)

    const parcoursTitle = (
        <>
            Le Grand Village<br />
            de Saint-Nicolas
        </>
    );

    const mapRef = useRef();


    const intro= (
        <>
            La Place Charles III déborde de vie et de lumière. Les chalets en bois du Grand Village de Saint-
            Nicolas attirent des visiteurs curieux, tandis que, juste à côté, le marché couvert pulse d’activité.
            Les effluves de petits plats mijotés et de pâtisseries fraîchement sorties du four se mêlent dans
            l’air.<br/>
            <br/>
            Claudon, Jacquot et Philippe se fraient un chemin à travers la foule du marché, admirant les étals
            et savourant une courte accalmie après leurs mésaventures précédentes.
        </>
    )

    const introFullText = (
        <>
            « On pourrait s’arrêter ici pour goûter un truc… » propose Claudon, les yeux fixés sur un stand de
            fromages.<br/>
            Mais Jacquot, un peu nerveux, surveille les alentours.<br/>
            « On ferait mieux de ne pas traîner trop longtemps. On ne sait jamais… »<br/>
            <br/>
            À cet instant, un rugissement retentit depuis un stand voisin :<br/>
            « Vous voilà, petits vauriens ! »<br/>
            Les trois garçons sursautent. Le boucher du marché, un colosse au tablier taché et au hachoir
            bien affûté, avance à grands pas vers eux, l’air furieux.<br/>
            <br/>
            « Vous pensiez pouvoir m’échapper comme la dernière fois ?! Revenez ici ! Vous ne pourrez pas
            compter sur le Saint-Nicolas pour vous sauver cette fois-ci… »<br/>
            <br/>
            Les enfants se dispersent dans la foule, paniqués. Claudon, Philippe et Jacquot foncent vers les
            stands de fruits et légumes. Le boucher, malgré son imposante carrure, progresse rapidement.<br/>
            <br/>
            Philippe trébuche presque en arrivant au stand d’un vieux marchand de fruits et légumes, qui trie
            calmement ses étals. Voyant l’urgence dans leurs regards, le marchand les interpelle :<br/>
            « Hé, les petits, tout va bien ? »<br/>
            « Le boucher nous poursuit ! » s’exclame Philippe.<br/>
            <br/>
            Le vieil homme jette un coup d’oeil au boucher qui approche, menaçant, et esquisse un sourire
            malin.<br/>
            « Ne vous inquiétez pas, je m’occupe de ce vieux grincheux ! »<br/>
            Il attrape discrètement une poignée de noix sur son étal et les laisse tomber, l’air de rien, juste
            devant le boucher.<br/>
            « Vous allez voir ce que je leur réserve à ces… » commence le boucher, avant que son pied ne
            roule sur les noix.<br/>
            <br/>
            Dans un fracas spectaculaire, le boucher perd l’équilibre et atterrit lourdement sur les pavés en
            tentant de se retenir à une cagette de choux qui finit par terre avec lui. Son hachoir est projeté en
            l’air.<br/>
            « Aïe ! Mais c’est pas possible ! » Hurle-t-il. Alors qu’il essaye de se relever, le hachoir qui
            tourbillonne au dessus de lui, retombe à pleine vitesse pour atterrir… dans un chou qui se
            retrouve coupé en deux juste entre ses deux jambes. Le boucher blêmit et retombe au sol le
            temps de se remettre de ses émotions.<br/>
            <br/>
            La scène attire l’attention des passants, qui éclatent de rire en voyant le colosse ainsi ridiculisé.
            Profitant de la diversion, le marchand fait signe aux enfants :<br/>
            « Allez, filez maintenant ! Et faites attention à vous. »<br/>
            <br/>
            Les trois garçons ne se font pas prier. Ils se faufilent hors du marché, les joues encore rouges de
            leur frayeur, mais le coeur léger grâce à l’intervention du vieux marchand.<br/>
            « On l’a échappé belle ! » souffle Claudon, essoufflé.<br/>
            Philippe éclate de rire en mimant le boucher glisser..<br/>
            « On devrait vraiment remercier ce marchand. Il nous a sauvés la mise ! »<br/>
            Jacquot, un sourire en coin, regarde autour de lui.<br/>
            « On devrait aussi éviter de retourner dans ce marché avant un bon moment… »<br/>
            Et sur ces mots, les trois aventuriers poursuivent leur périple à travers le Grand Village de Saint-
            Nicolas, en se dirigeant vers leur prochaine étape.
        </>
    )

    const lignes = [
        {icon : ligneA, alt: 'ligne A'},
        {icon : T2, alt: 'ligne T2'},
        {icon : T3, alt: 'ligne T3'},
        {icon : T4, alt: 'ligne T4'},
        {icon : corol, alt: 'corol'},
        {icon : ligne11, alt: 'ligne 11'},
        {icon : ligne12, alt: 'ligne 12'},
        {icon : ligne13, alt: 'ligne 13'},
        {icon : ligne15, alt: 'ligne 15'},
        {icon : ligne16, alt: 'ligne 16'},
        {icon : citadine, alt: 'citadine'},
        {icon : lune, alt: 'Pleine Lune'},
        {icon : resago, alt: 'résa go'},
        {icon : flexo, alt: 'flexo soirée'},
    ]

    const charles3Desc = "En voilà un qui porte bien son nom ! Porté par l’association Les Vitrines de Nancy, c’est non seulement le plus important, mais également le plus ancien des villages de Saint-Nicolas. Avec ses soixantes chalets en bois installés en cercle, ses miliers de lumières et son décors féérique, il forme un dédale fantastique débordant d’idées cadeaux à chiner et de spécialités à déguster."

    const ventreNancy = (
        <>
            Auparavant, le marché était situé aux halles de la place Saint-Epvre, en Ville-Vieille. Mais lors de la
            création de la Ville-Neuve au XVIème siècle par le Duc de Lorraine Charles III, l’emplacement d’un marché de
            plein air a été prévu sur une esplanade entre la rue St Dizier et la rue des Ponts. <br/>
            <br/>
            Ainsi, depuis plus de quatre siècles, le marché occupe ce même emplacement.
            Le bâtiment actuel, construit par l'architecte Prosper Morey en 1852, a été plusieurs fois modifié et rénové
            pour prendre sa configuration actuelle, avec notamment l’ajout d’une verrière recouvrant la hall
            centrale.<br/>
            <br/>
            Aujourd’hui, le marché central de Nancy regroupe près de 65 commerçants proposant des produits de qualité :
            fruits, légumes, viande, poisson, épicerie fine, spécialités lorraines ou exotiques, fleurs….
        </>
    )

    const restauration1= (
        <>
            Sur le marché de Noël : on file tester le <strong>Beerawecka</strong> ! Un gâteau composé de fruits secs et
            de fruits confits, le tout macéré dans du Schnaps (eau-de-vie) de quetsche, de mirabelle ou de cerises
            (kirsch) et maintenus par une très fine couche de pâte bien épicée.<br/>
            <br/>
            Au marché couvert, si on peut faire honneur à l’âne de Saint-Nicolas en dégustant des
            bonnes <strong>carottes</strong>, on doit surtout savourer les <strong>oranges</strong> ou se prévoir un
            bon <strong>petit salé</strong> proposé par les commerçants, digne de la légende du saint patron de la
            Lorraine !
        </>
    )

    const infoVillage1 = {
        dates: {
            opening: '22/11',
            closing: '28/12'
        },
        schedule: [
            {
                type:'single',
                days:['Lundi'],
                hours : '12h - 20h'
            },
            {
                type: 'range',
                days: {
                    from: 'mardi',
                    to: 'vendredi'
                },
                hours : '11h - 20h'
            },
            {
                type : 'single',
                days: ['Samedi et dimanche'],
                hours: '10h - 20h'
            }
        ],
        nocturne: "Nocturne jusqu'à 21h pour les métiers de la bouche",
        closed : 'Fermé le 25 décembre',
        adresse : "Place Charles 3, 54000 Nancy",
        transport : "Place Charles III - Point Central"
    }


    return(
        <div className="village1 village">
            {/*Header with audio player*/}
            <div className="etape-header">
                <div className="titre-village">
                    <img src={village1} alt=""/>
                    <h1 className="etape-header-title">{parcoursTitle}</h1>
                </div>

                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} text2={introFullText} image1={boucher} image2={enfants}/>
            </div>

            {/*Village description*/}
            <div className="presentation-village">
                <VillagePresentation place='Place Charles III' image={placeCharles3} description={charles3Desc} information='Informations sur le Village' lieu={infoVillage1} lignes={lignes} />
            </div>

            {/*Ventre de Nancy*/}
            <div className='ventre-nancy'>
                <Article1 subtitle="Le marché couvert" title="Le ventre de Nancy" description={ventreNancy} image={marcheCouvert} mention="Crédits : https://www.nancy-focus.com + Destination Nancy" />
            </div>

            {/*Restauration*/}
            <div className='village1-restauration'>
                <Restauration image1={cake} image2={saucisse} image3={carotteOrange} description={restauration1} />
            </div>

            {/*petites etoiles*/}
            <div className="stars">
                <img src={stars} alt="" />
            </div>

            {/*Arabesque*/}
            <div className="arabesque">
                <img src={arabesque} alt="" />
            </div>

            {/*Evenements*/}
            <div className="village1-carousel">
                <h3>Les événements à proximité</h3>
                <h2>Ne pas rater:</h2>
                <Carousel />
            </div>

            {/*TODO : patrimoine proche*/}

            <div className="village-carte">
                <div className="village-carte-titre">À (re)découvrir</div>
                <div className="village-carte-soustitre">Le patrimoine proche</div>
                <div className="village-carte-carte">
                    {
                        lieux.length > 0 &&
                        <MapContainer ref={mapRef} center={etapes.find((etape) => etape.id === etapeCourante).coords}
                                      zoom={20} scrollWheelZoom={true} style={{
                            width: '100%',
                            height: '100%',
                        }} whenReady={(e) => {
                            e.target.on('popupopen', function (e) {
                                if (e.popup.options.id.length === 1) {
                                    setEtapeCourante(e.popup.options.id);
                                }
                                e.target.closePopup();
                                e.popup.options.id === "1" ?
                                    navigate("/village1") :
                                    e.popup.options.id === "2" ?
                                        navigate("/village2") :
                                        e.popup.options.id === "3" ?
                                            navigate("/village3") :
                                            e.popup.options.id === "4" ?
                                                navigate("/village4") :
                                                e.popup.options.id === "5" ?
                                                    navigate("/village5") :
                                                    e.popup.options.id === "6" ?
                                                        navigate("/village6") :
                                                        e.popup.options.id === "7" ?
                                                            navigate("/village7") :
                                                            e.popup.options.id === "8" ?
                                                                navigate("/village8") :
                                                                navigate("/lieu/" + e.popup.options.id);
                            });
                        }}>

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                            />

                            {
                                etapes.map((etape) => {
                                    return (
                                        <Marker position={etape.coords} icon={L.icon({
                                            iconUrl: etape.id === "1" ? v1 :
                                                etape.id === "2" ? v2 :
                                                    etape.id === "3" ? v3 :
                                                        etape.id === "4" ? v4 :
                                                            etape.id === "5" ? v5 :
                                                                etape.id === "6" ? v6 :
                                                                    etape.id === "7" ? v7 :
                                                                        v8,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={etape.id}>
                                                {etape.titre}
                                            </Popup>
                                            <Tooltip direction="top">
                                                {etape.titre}
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                lieux?.map((lieu) => {
                                    return (
                                        <Marker position={lieu?.coords} icon={L.icon({
                                            iconUrl: typeNameToObject(lieu.type).icon,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={lieu.name}>
                                                {lieu.name}
                                            </Popup>
                                            <Tooltip direction="top">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "150px",
                                                }}>
                                                    <img style={{
                                                        width: "150px",
                                                        height: "112px",
                                                        objectFit: "cover",
                                                    }}
                                                         src={"https://preprodback-istorio.monolithe.studio/files/" + lieu?.imgs[0]}
                                                         alt="vignette lieu"/>
                                                    <div style={{
                                                        textWrap: "wrap",
                                                        fontWeight: "bold",
                                                        marginTop: "5px",
                                                    }}>
                                                        {lieu.name}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }

                        </MapContainer>
                    }
                </div>
            </div>

            {/*TODO : carousel partenaire*/}

        </div>
    )
}