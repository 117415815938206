import {createContext, useEffect, useState} from "react";

export const EtapeContext = createContext();

export function EtapeContextProvider({children}) {

    const etapes = [{
        id: "1",
        titre: "Grand village de Saint-Nicolas",
        coords: [48.6889974, 6.1819316],
        adresse: "Place Charles III, 54100 Nancy, France",
        type: "village",
    }, {
        id: "2",
        titre: "Village Le Bois des 3 Petits Enfants",
        coords: [48.6898834, 6.1752639],
        adresse: "Place Simone Veil, 54100 Nancy, France",
        type: "village",
    }, {
        id: "3",
        titre: "Village de la Grande roue et de la Patinoire",
        coords: [48.695716857910156, 6.182108402252197],
        adresse: "Place de la Carrière, 54000 Nancy, France",
        type: "village",
    }, {
        id: "4",
        titre: "Le Hameau gourmand de Saint-Nicolas",
        coords: [48.6942403, 6.1821477],
        adresse: "Place Vaudémont, 54100 Nancy, France",
        type: "hameau",
    }, {
        id: "5",
        titre: "Le Hameau de la Craffe",
        coords: [48.6989009, 6.1777722],
        adresse: "Porte de la Craffe, Grande Rue, 54100 Nancy, France",
        type: "hameau",
    }, {
        id: "6",
        titre: "Le Hameau des Bons Vivants",
        coords: [48.6964074, 6.1792289],
        adresse: "Place Saint-Epvre, 54100 Nancy, France",
        type: "hameau",
    }, {
        id: "7",
        titre: "Le Hameau gourmand des Vosges",
        coords: [48.6852112, 6.186819],
        adresse: "Place des Vosges, 54100 Nancy, France",
        type: "hameau",
    }, {
        id: "8",
        titre: "Le petit Hameau de Nico et Moujat",
        coords: [48.6912025, 6.1839515],
        adresse: "Rue du Pont Mouja, 54100 Nancy, France",
        type: "hameau",
    }];

    const [etapeCourante, setEtapeCourante] = useState(etapes[0].id);

    return (
        <EtapeContext.Provider value={{etapes, etapeCourante, setEtapeCourante}}>
            {children}
        </EtapeContext.Provider>
    );
};