import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Home from "./Pages/Home";
import Parcours from "./Pages/Parcours";
import {EtapeContextProvider} from "./Contexts/EtapeContext";
import {FooterProvider} from "./Contexts/FooterContext";
import Navbar from "./Components/Navbar/Navbar";
import "./Styles/index.css";
import {LieuxContextProvider} from "./Contexts/LieuxContext";
import Village1 from "./Pages/Village1";
import Village2 from "./Pages/Village2";
import Village3 from "./Pages/Village3";
import Village4 from "./Pages/Village4";
import Village5 from "./Pages/Village5";
import Village6 from "./Pages/Village6";
import Village7 from "./Pages/Village7";
import Village8 from "./Pages/Village8";
import Lieu from "./Pages/Lieu/Lieu";


const FontStyles = createGlobalStyle `
    @import url(http://fonts.googleapis.com/earlyaccess/jejuhallasan.css);
    @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LieuxContextProvider>
        <EtapeContextProvider>
            <FontStyles />
            <Router>
                <Navbar />
            <FooterProvider>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/parcours" element={<Parcours />}/>
                    <Route path="/village1" element={<Village1 />} />
                    <Route path="/village2" element={<Village2 />} />
                    <Route path="/village3" element={<Village3 />} />
                    <Route path="/village4" element={<Village4 />} />
                    <Route path="/village5" element={<Village5 />} />
                    <Route path="/village6" element={<Village6 />} />
                    <Route path="/village7" element={<Village7 />} />
                    <Route path="/village8" element={<Village8 />} />
                    <Route path="/lieu/:name" element={<Lieu />} />
                </Routes>
            </FooterProvider>
            </Router>

        </EtapeContextProvider>
        </LieuxContextProvider>
    </React.StrictMode>

)
