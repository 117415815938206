import '../../Styles/village3.scss'
import village3 from "../../Assets/icons/villages/village3.png";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import enfants from "../../Assets/enfants.png";
import VillagePresentation from "../../Components/VillagePresentation/villagePresentation";
import Article1 from "../../Components/Article1/article1";
import placeCarriere from '../../Assets/villages/placeCarriere.png'
import Article2 from "../../Components/Article2/article2";
import ane from '../../Assets/ane.png';
import stars from "../../Assets/stars.png";
import arabesque from "../../Assets/arabesque.png";
import Carousel from "../../Components/Carousel/carousel";
import React, {useContext, useRef} from "react";
import roue from '../../Assets/villages/roue.png';
import rueEcuries from '../../Assets/villages/rueEcuries.png';
import marrons from '../../Assets/restauration/marrons.png';
import cocktail from '../../Assets/restauration/cocktail.png';
import marrons2 from '../../Assets/restauration/marrons2.png';
import Restauration3 from "../../Components/Restauration3/restauration3";
import ligne12 from "../../Assets/icons/lignes/12.png";
import ligne13 from "../../Assets/icons/lignes/13.png";
import ligne16 from "../../Assets/icons/lignes/16.png";
import citadine from "../../Assets/icons/lignes/citadine.png";
import resago from "../../Assets/icons/lignes/resago.png";
import flexo from "../../Assets/icons/lignes/flexo.png";
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import L from "leaflet";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";
import {useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";

export default function Village3(){

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);
    const {typeNameToObject, lieux} = useContext(LieuxContext);


    const mapRef = useRef();

    const parcoursTitle = (
        <>
            Village de la Grande Roue <br />
            et de la Patinoire
        </>
    );

    const intro= (
        <>
            La Place de la Carrière, majestueusement illuminée, semble enveloppée d’une sérénité magique.
            La grande roue, dressée fièrement au centre, fait scintiller ses mille lumières, tandis que la
            patinoire résonne du rire des petits et grands. La course-poursuite du marché couvert paraît déjà
            loin, et les trois enfants profitent enfin d’un moment de répit.<br/>
            Claudon s’appuie contre un banc, les joues encore rouges d’effort.
        </>
    )

    const introFullText=(
        <>
            « On pourrait s’asseoir un peu, non ? » propose-t-il.<br/>
            <br/>
            Jacquot hoche la tête.<br/>
            « Oui, c’est une bonne idée. Cette fois, je crois qu’on l’a vraiment semé. »<br/>
            <br/>
            Philippe, lui, observe la grande roue avec fascination.<br/>
            « Regardez comme c’est beau ! On pourrait monter, juste pour voir toute la ville d’en haut… »<br/>
            <br/>
            Mais un bruit étrange interrompt leur discussion. Un hihan retentit soudain, suivit de bruits de
            sabots sur les pavés.<br/>
            <br/>
            « C’était quoi ça ? » demande Claudon, se redressant.<br/>
            <br/>
            Les trois garçons se tournent et découvrent une silhouette improbable : un âne gris, portant une
            couverture rouge brodée d’or, trotte maladroitement entre les chalets.<br/>
            Philippe écarquille les yeux.<br/>
            <br/>
            « C’est l’âne de Saint Nicolas ! »<br/>
            <br/>
            Jacquot s’avance prudemment.<br/>
            « Qu’est-ce qu’il fait là, tout seul ? »<br/>
            <br/>
            L’âne s’arrête devant eux, les regardant de ses grands yeux doux. Il secoue ses longues oreilles,
            comme s’il voulait attirer leur attention.<br/>
            Claudon s’approche doucement et pose une main sur le museau de l’animal.<br/>
            « Il a l’air perdu. On ne peut pas le laisser comme ça. »<br/>
            <br/>
            Philippe remarque alors un petit sac de toile attaché à la selle de l’âne. Il l’ouvre et en sort
            quelques pains d’épices et des mandarines.<br/>
            « Il porte les cadeaux de Saint Nicolas ! »<br/>
            <br/>
            Jacquot réfléchit un instant.<br/>
            « Il doit chercher son chemin pour le retrouver. Mais comment on va faire ? On ne sait même pas
            où il est. »<br/>
            <br/>
            Claudon sourit en désignant un stand voisin où une vendeuse de marrons grillés les regarde avec
            curiosité.<br/>
            « Peut-être qu’elle pourra nous aider ? »<br/>
            <br/>
            Les enfants mènent l’âne jusqu’au stand, expliquant leur découverte à la vendeuse. Celle-ci,
            amusée, leur donne une information précieuse :<br/>
            « On m’a dit que Saint Nicolas devait arriver bientôt du côté de la place Stanislas. Si vous
            l’accompagnez jusque-là, je suis sûre qu’il retrouvera son maître. »<br/>
            <br/>
            Les garçons se regardent, puis Jacquot prend la parole.<br/>
            « Alors c’est décidé. On va l’emmener là-bas. »<br/>
            <br/>
            L’âne, comme s’il comprenait, secoue à nouveau ses oreilles et se met en route, entraînant les
            enfants à travers le Village de la Grande Roue et de la Patinoire. Sur le chemin, ils croisent des
            familles émerveillées, des patineurs rieurs, et des visiteurs curieux qui sourient en voyant l’étrange
            cortège.<br/>
            <br/>
            « Tu crois qu’il est content de nous voir l’aider ? » demande Philippe en caressant l’encolure de
            l’âne.<br/>
            <br/>
            Claudon rit.<br/>
            « Il n’a pas l’air de se plaindre en tout cas. »<br/>
            <br/>
            Et tandis que l’âne avance paisiblement, les enfants sentent la tension de leurs précédentes
            aventures se dissiper, savourant cette mission aussi inattendue que réconfortante.
        </>
    )

    const patinoireDesc="Imaginez : faire du patin à glace au cœur d’un ensemble classé au patrimoine mondial de l’humanité par l’UNESCO, puis s’envoler à trente mètres de haut pour profiter d’une vue extraordinaire sur la cité ducale... Ouvrez les yeux : le rêve est devenu réalité ! Grâce à l’installation d’une véritable patinoire de 40 mètres. Voilà qui ajoute une touche de féérie à ce beau village ; d’autant qu’on y retrouve toujours manèges pour enfants, chalets gourmands et même un stand champagne !"

    const rueDesEcuries = (
        <>
            Cachée entre le Place de la Carrière et les Terrasses de la Pépinière, la rue des écuries est une voie
            historique au sein de la vieille ville de Nancy. <br/>
            <br/>
            En y accèdant par un porche le long de la place de la Carrière ou en passant sous la coursive qui mène de
            l’Hôtel Guerrier de Dumast au Palais du Gouvernement, on constate tout de suite son étrange configuration.
            Et pour cause : cette petite rue avait pour vocation d’accueillir les écuries ducales !<br/>
            <br/>
            Les grilles de Jean Lamour, situées aujourd’hui sur la Place Carrière, étaient au départ destinées à orner
            les extrémités de la rue des écuries. Maintenant, ces anciennes écuries ont été transformées en garage pour
            les habitants.
        </>
    )

    const carriereDesc1 = "Difficile à croire mais, en 1551, la place de la Carrière est avant tout une rue, assez large et rectiligne. La “rue Neuve”. Elle fait partie, avec la place Stanislas et la place d'Alliance, d'un ensemble de trois places, inscrit au Patrimoine Mondial de l'Unesco en 1983 ! Du fait de la configuration de cet espace, joutes, courses et tournois équestres sont organisés très tôt. En 1605, le conseil de ville fait marquer l’espace de jeux par une clôture en chêne tout autour de la place (que l'on voit bien sur la gravure de Jacques Callot en illustration)."

    const carriereDesc2 = (
        <>
            C'est au cours du XVII ème siècle que le nom de la Carrière remplace le nom de rue Neuve. Cette dénomination fait directement référence à l'usage de la place : « la carrière » c'est le champ de courses.<br/>
            <br/>
            La place garde cette appellation jusqu'à nos jours, à l'exception de l'époque révolutionnaire où elle prend temporairement le nom de place de la République démocratique.
        </>
    )

    const restauration3 = (
        <>
            Sur le marché de Noël : on profite des stands de <strong>marrons chauds</strong> ou de <strong>vin
            chaud</strong> pour tenir dans le froid de l’hiver Lorrain.<br/>
            <br/>
            Et si on veut se dépayser, on va faire un tour au Louis XV qui, le temps des travaux de rénovation de
            l’Hôtel de la Reine, a pris ses quartiers dans le décors monumental du Palais du Gouverneur ! Là, on pose
            ses manteaux dans les petites alcôves aménagées et on déguste un délicieux <strong>cocktail</strong> en
            grignottant une assiette gourmande.
        </>
    )

    const lignes3 = [
        {icon : ligne12, alt: 'ligne 12'},
        {icon : ligne13, alt: 'ligne 13'},
        {icon : ligne16, alt: 'ligne 16'},
        {icon : citadine, alt: 'citadine'},
        {icon : resago, alt: 'résa go'},
        {icon : flexo, alt: 'flexo soirée'},
    ]

    const infoVillage3 = {
        dates: {
            opening: '22/11',
            closing: '05/01'
        },
        schedule: [
            {
                type:'single',
                days:['Mardi, mercredi, dimanche'],
                hours : '11h - 22h'
            },
            {
                type: 'range',
                days: {
                    from: 'jeudi',
                    to: 'samedi'
                },
                hours : '11h - 23h'
            }
        ],
        closed : "Fermé le lundi à l'exception de la grande roue",
        adresse : "Place de la Carrière, 54000 Nancy",
        transport : "Amerval"
    }


    return(
        <div className="village3 village">
            {/*Header with audio player*/}
            <div className="village3-header">
                <div className="titre-village">
                    <img src={village3} alt=""/>
                    <h1 className="etape-header-title">{parcoursTitle}</h1>
                </div>

                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} text2={introFullText} image1={ane} image2={enfants}/>
            </div>

            {/*Village description*/}
            <div className="presentation-village">
                <VillagePresentation place='Place de la Carrière' image={roue} description={patinoireDesc} information='Informations sur le Village' lieu={infoVillage3} lignes={lignes3} />
            </div>

            {/*rue des écuries*/}
            <div className='ventre-nancy'>
                <Article1 subtitle="La rue des écuries" title="Quelle rue curieuse !" description={rueDesEcuries}
                          image={rueEcuries} mention=""/>
            </div>

            {/*premier grand magasin*/}
            <div className='carriere'>
                <Article2 subtitle="La Place de la Carrière" title="Le coeur festif de la ville"
                          description1={carriereDesc1} description2={carriereDesc2} image={placeCarriere} mention=""/>
            </div>


            {/*Restauration*/}
            <div className='village1-restauration'>
                <Restauration3 image1={marrons} image2={cocktail} image3={marrons2} description={restauration3}/>
            </div>

            {/*petites etoiles*/}
            <div className="stars">
                <img src={stars} alt=""/>
            </div>

            {/*Arabesque*/}
            <div className="arabesque">
                <img src={arabesque} alt=""/>
            </div>

            {/*Evenements*/}
            <div className="village1-carousel">
                <h3>Les événements à proximité</h3>
                <h2>Ne pas rater:</h2>
                <Carousel/>
            </div>

            {/*/!*TODO : patrimoine proche*!/*/}

            <div className="village-carte">
                <div className="village-carte-titre">À (re)découvrir</div>
                <div className="village-carte-soustitre">Le patrimoine proche</div>
                <div className="village-carte-carte">
                    {
                        lieux.length > 0 &&
                        <MapContainer ref={mapRef} center={etapes.find((etape) => etape.id === etapeCourante).coords}
                                      zoom={20} scrollWheelZoom={true} style={{
                            width: '100%',
                            height: '100%',
                        }} whenReady={(e) => {
                            e.target.on('popupopen', function (e) {
                                if (e.popup.options.id.length === 1) {
                                    setEtapeCourante(e.popup.options.id);
                                }
                                e.target.closePopup();
                                e.popup.options.id === "1" ?
                                    navigate("/village1") :
                                    e.popup.options.id === "2" ?
                                        navigate("/village2") :
                                        e.popup.options.id === "3" ?
                                            navigate("/village3") :
                                            e.popup.options.id === "4" ?
                                                navigate("/village4") :
                                                e.popup.options.id === "5" ?
                                                    navigate("/village5") :
                                                    e.popup.options.id === "6" ?
                                                        navigate("/village6") :
                                                        e.popup.options.id === "7" ?
                                                            navigate("/village7") :
                                                            e.popup.options.id === "8" ?
                                                                navigate("/village8") :
                                                                navigate("/lieu/" + e.popup.options.id);
                            });
                        }}>

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                            />

                            {
                                etapes.map((etape) => {
                                    return (
                                        <Marker position={etape.coords} icon={L.icon({
                                            iconUrl: etape.id === "1" ? v1 :
                                                etape.id === "2" ? v2 :
                                                    etape.id === "3" ? v3 :
                                                        etape.id === "4" ? v4 :
                                                            etape.id === "5" ? v5 :
                                                                etape.id === "6" ? v6 :
                                                                    etape.id === "7" ? v7 :
                                                                        v8,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={etape.id}>
                                                {etape.titre}
                                            </Popup>
                                            <Tooltip direction="top">
                                                {etape.titre}
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                lieux?.map((lieu) => {
                                    return (
                                        <Marker position={lieu?.coords} icon={L.icon({
                                            iconUrl: typeNameToObject(lieu.type).icon,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={lieu.name}>
                                                {lieu.name}
                                            </Popup>
                                            <Tooltip direction="top">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "150px",
                                                }}>
                                                    <img style={{
                                                        width: "150px",
                                                        height: "112px",
                                                        objectFit: "cover",
                                                    }}
                                                         src={"https://preprodback-istorio.monolithe.studio/files/" + lieu?.imgs[0]}
                                                         alt="vignette lieu"/>
                                                    <div style={{
                                                        textWrap: "wrap",
                                                        fontWeight: "bold",
                                                        marginTop: "5px",
                                                    }}>
                                                        {lieu.name}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }

                        </MapContainer>
                    }
                </div>
            </div>

            {/*/!*TODO : carousel partenaire*!/*/}

            {/*<div className='modale-infos-village'>*/}
            {/*    <InfoModal lieu="Place de la Carrière, 54000 Nancy" arret="Amerval" lignes={lignes3} />*/}
            {/*</div>*/}
        </div>
    )
}