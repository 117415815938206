import '../../Styles/village8.scss'
import '../../Styles/village.scss'
import village8 from "../../Assets/icons/villages/village8.png";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import fouettardBoucher from "../../Assets/fouettardBoucher.png";
import enfants from "../../Assets/enfants.png";
import VillagePresentation from "../../Components/VillagePresentation/villagePresentation";
import Article1 from "../../Components/Article1/article1";
import vinChaud2 from '../../Assets/restauration/vinChaud2.png';
import poutine from '../../Assets/restauration/poutine.png';
import arabesque from "../../Assets/arabesque.png";
import Carousel from "../../Components/Carousel/carousel";
import nicoEtMouja from '../../Assets/villages/NicoEtMouja.png';
import React, {useContext, useRef} from "react";
import Article3 from "../../Components/Article3/article3";
import Restauration4 from "../../Components/Restauration4/restauration4";
import ligne12 from "../../Assets/icons/lignes/12.png";
import ligne13 from "../../Assets/icons/lignes/13.png";
import ligne16 from "../../Assets/icons/lignes/16.png";
import citadine from "../../Assets/icons/lignes/citadine.png";
import resago from "../../Assets/icons/lignes/resago.png";
import flexo from "../../Assets/icons/lignes/flexo.png";
import {MapContainer, Marker, Popup, TileLayer, Tooltip} from "react-leaflet";
import L from "leaflet";
import v1 from "../../Assets/icons/v1.png";
import v2 from "../../Assets/icons/v2.png";
import v3 from "../../Assets/icons/v3.png";
import v4 from "../../Assets/icons/v4.png";
import v5 from "../../Assets/icons/v5.png";
import v6 from "../../Assets/icons/v6.png";
import v7 from "../../Assets/icons/v7.png";
import v8 from "../../Assets/icons/v8.png";
import {useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {LieuxContext} from "../../Contexts/LieuxContext";
import cathedrale from '../../Assets/villages/cathedrale.png';
import deuxNancy from '../../Assets/villages/deuxNancy.png'

export default function Village8(){

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);
    const {typeNameToObject, lieux} = useContext(LieuxContext);


    const mapRef = useRef();

    const parcoursTitle = (
        <>
            Le petit hameau <br />
            de Nico et Mouja
        </>
    );

    const intro= (
        <>
            Bons petits plats, bonne ambiance,   <br/>
        </>
    )

    const porteSaintNicolas = (
        <>
            On s’étonne de ne pas trouver à Nancy une cathédrale de style gothique... <br/>
            <br/>
            La raison est simple et ce n’est pas faute d’avoir essayé. À la fin du XVIe siècle, le duc de Lorraine, Charles III, voulait voir Nancy érigée en évêché. Le roi de France Henri IV, possesseur de ce qu'on appelait alors les Trois-Évêchés (Toul, Metz et Verdun), fit en sorte que le pape Clément VIII refuse à ses puissants voisins ce qui aurait alors été un symbole fort pour la Lorraine. En compensation, Clément proposa, en 1602, la création d'une primatiale... Qui mit du temps à voir le jour ! <br/>
            <br/>
            Un siècle plus tard, soucieux de l'éclat artistique de son duché, le duc Léopold fit appel à l'architecte italien Giovanni Betto qui présenta un projet, de style classique, inspiré de l'église romaine San Andrea della Valle. Le chantier démarra en 1703. Deux ans plus tard, le duc se mit en tête de demander l'avis du grand architecte de Louis XIV, Jules Hardouin-Mansart. Celui-ci agrandit le projet de Betto, lui donnant plus d'ampleur. <br/>
            <br/>
            Être capitale d’un état indépendant, ça oblige parfois à quelques concessions. Mais au regard du résultat, on peut dire que Nancy n’a pas perdu grand chose dans l’affaire.
        </>
    )

    const deuxVilles = (
        <>
            Savez-vous quelle est la particularité urbanistique de Nancy ? Non, ça n’est pas le fait que cette ville à l’histoire si riche est aujourd’hui la quatrième ville de France avec le plus de monuments classés (260, derrière Paris, Bordeaux et La Rochelle).
            Nancy est une ville qui n’a pas grandi progressivement depuis son centre !<br/>
            <br/>
            La raison de cette originalité est simple : en 1590, afin de ne pas toucher aux puissantes fortifications qui encerclaient sa ville historique, le duc Charles III demande à l'Italien Girolamo Citoni de réaliser une “nouvelle-ville” de style Renaissance, au sud de la “vieille-ville” médiévale. Ce dernier y trace des rues larges et droites selon un plan hippodamien, exception faite de la rue Saint-Nicolas (ancien faubourg) qui garde son tracé préexistant.<br/>
            <br/>
            C’est la raison pour laquelle Nancy n’a pas vraiment “un” centre ville, mais plutôt trois, depuis que Stanislas fit bâtir une troisième ville, de style XVIIIe, qui sera un véritable trait d’union entre les deux Cités historiques.
        </>
    )

    const restauration7 = (
        <>
            Envie d’une énorme <strong>tartiflette</strong> bien réconfortante ? D’une
            délicieuse <strong>poutine</strong> bien gourmande ? Ou d’un bon petit <strong>vin chaud ou jus de fruit
            chaud</strong> aux épices délicats ? Vous trouverez votre bonheur sur le Hameau !<br/>
            <br/>
            Et si vous souhaitez vous mettre au chaud, n’hésitez pas à pousser les portes du discret “Big sister”,
            décerné meilleur restaurant vegan de France en 2023 par le magazine Luxlife.
        </>
    )

    const nicoMoujaDesc='C’est la nouvelle étape (obligée) de ce parcours gourmet, festif et culturel : un charmant' +
        ' hameau situé au coeur de la rue du Pont Mouja, récemment piétonnisée. Organisé par la toute jeune association qui regroupe les commerçants et restaurateurs du quartier, il est constitué de six chalets alléchants.'

    const lignes8 = [
        {icon : ligne12, alt: 'ligne 12'},
        {icon : ligne13, alt: 'ligne 13'},
        {icon : ligne16, alt: 'ligne 16'},
        {icon : citadine, alt: 'citadine'},
        {icon : resago, alt: 'résa go'},
        {icon : flexo, alt: 'flexo soirée'},
    ]

    const infoVillage8 = {
        dates: {
            opening: '23/11',
            closing: '05/01'
        },
        schedule: [
            {
                type:'single',
                days:['Mercredi et jeudi'],
                hours : '16h - 22h'
            },
            {
                type:'single',
                days:['Vendredi et samedi'],
                hours : '11h - 23h'
            },
            {
                type:'single',
                days:['Dimanche'],
                hours : '11h - 21h'
            }
        ],
        closed : "Fermeture les lundis et mardis",
        adresse : "Place Vaudémont, 54000 Nancy",
        transport : "Amerval"
    }

    return(
        <div className="village8 village">
            {/*Header with audio player*/}
            <div className="village8-header">
                <div className="titre-village">
                    <img src={village8} alt=""/>
                    <h1 className="etape-header-title">{parcoursTitle}</h1>
                </div>

                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <ParcoursDescription text={intro} link={"/"} image1={fouettardBoucher} image2={enfants}/>
            </div>

            {/*Village description*/}
            <div className="presentation-village">
                <VillagePresentation place='Porte Saint-Nicolas' image={nicoEtMouja} description={nicoMoujaDesc}
                                     information='Informations sur le Hameau' lieu={infoVillage8} lignes={lignes8}/>
            </div>

            {/*jardin de la citadelle*/}
            <div className='ventre-nancy'>
                <Article1 subtitle="La Porte Saint-Nicolas" title="Vestige d'une place forte"
                          description={porteSaintNicolas} image={cathedrale}
                          mention=""/>
            </div>

            {/*vieille ville*/}
            <div className='villeVille'>
                <Article3 subtitle='"Ville vieille" et "Ville neuve"' title="Deux Nancy pour le prix d'une" description={deuxVilles}
                          image={deuxNancy} mention=""/>
            </div>


            {/*Restauration*/}
            <div className='village1-restauration'>
                <Restauration4 image2={vinChaud2} image3={poutine} description={restauration7}/>
            </div>

            {/*Arabesque*/}
            <div className="arabesque">
                <img src={arabesque} alt=""/>
            </div>

            {/*Evenements*/}
            <div className="village1-carousel">
                <h3>Les événements à proximité</h3>
                <h2>Ne pas rater:</h2>
                <Carousel/>
            </div>

            {/*/!*TODO : patrimoine proche*!/*/}

            <div className="village-carte">
                <div className="village-carte-titre">À (re)découvrir</div>
                <div className="village-carte-soustitre">Le patrimoine proche</div>
                <div className="village-carte-carte">
                    {
                        lieux.length > 0 &&
                        <MapContainer ref={mapRef} center={etapes.find((etape) => etape.id === etapeCourante).coords}
                                      zoom={20} scrollWheelZoom={true} style={{
                            width: '100%',
                            height: '100%',
                        }} whenReady={(e) => {
                            e.target.on('popupopen', function (e) {
                                if (e.popup.options.id.length === 1) {
                                    setEtapeCourante(e.popup.options.id);
                                }
                                e.target.closePopup();
                                e.popup.options.id === "1" ?
                                    navigate("/village1") :
                                    e.popup.options.id === "2" ?
                                        navigate("/village2") :
                                        e.popup.options.id === "3" ?
                                            navigate("/village3") :
                                            e.popup.options.id === "4" ?
                                                navigate("/village4") :
                                                e.popup.options.id === "5" ?
                                                    navigate("/village5") :
                                                    e.popup.options.id === "6" ?
                                                        navigate("/village6") :
                                                        e.popup.options.id === "7" ?
                                                            navigate("/village7") :
                                                            e.popup.options.id === "8" ?
                                                                navigate("/village8") :
                                                                navigate("/lieu/" + e.popup.options.id);
                            });
                        }}>

                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                            />

                            {
                                etapes.map((etape) => {
                                    return (
                                        <Marker position={etape.coords} icon={L.icon({
                                            iconUrl: etape.id === "1" ? v1 :
                                                etape.id === "2" ? v2 :
                                                    etape.id === "3" ? v3 :
                                                        etape.id === "4" ? v4 :
                                                            etape.id === "5" ? v5 :
                                                                etape.id === "6" ? v6 :
                                                                    etape.id === "7" ? v7 :
                                                                        v8,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={etape.id}>
                                                {etape.titre}
                                            </Popup>
                                            <Tooltip direction="top">
                                                {etape.titre}
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }
                            {
                                lieux?.map((lieu) => {
                                    return (
                                        <Marker position={lieu?.coords} icon={L.icon({
                                            iconUrl: typeNameToObject(lieu.type).icon,
                                            iconSize: [30, 30],
                                            iconAnchor: [15, 0],
                                            popupAnchor: [0, -30],
                                        })}>
                                            <Popup id={lieu.name}>
                                                {lieu.name}
                                            </Popup>
                                            <Tooltip direction="top">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    maxWidth: "150px",
                                                }}>
                                                    <img style={{
                                                        width: "150px",
                                                        height: "112px",
                                                        objectFit: "cover",
                                                    }}
                                                         src={"https://preprodback-istorio.monolithe.studio/files/" + lieu?.imgs[0]}
                                                         alt="vignette lieu"/>
                                                    <div style={{
                                                        textWrap: "wrap",
                                                        fontWeight: "bold",
                                                        marginTop: "5px",
                                                    }}>
                                                        {lieu.name}
                                                    </div>
                                                </div>
                                            </Tooltip>
                                        </Marker>
                                    )
                                })
                            }

                        </MapContainer>
                    }
                </div>
            </div>

            {/*/!*TODO : carousel partenaire*!/*/}

            {/*<div className='modale-infos-village'>*/}
            {/*    <InfoModal lieu="Place Vaudémont, 54000 Nancy" arret="Amerval" lignes={lignes4} />*/}
            {/*</div>*/}
        </div>
    )
}