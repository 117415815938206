import '../../Styles/article2.scss'
import React, {useState} from "react";
import clicVideo from '../../Assets/clicVideo.png'


export default function Article2({subtitle, title, description1, description2,description3, image, mention}) {

    const [isVisible, setIsVisible] = useState(true);

    const handleVideo = () => {
        setIsVisible(!isVisible);
        console.log(`visibility : ${isVisible}`);
    }

    return(
        <div className='article2'>
            <div className='article2-titles'>
                <h3>{subtitle}</h3>
                <h2>{title}</h2>
            </div>

            {title === "Au coeur de l'histoire" &&
                <>
                    <img src={image} alt="" className='article2-image' style={{cursor: 'pointer'}} onClick={handleVideo}
                         style={isVisible ?
                             {display: 'flex'} :
                             {display: 'none'}}/>
                    <img src={clicVideo} alt="" className='lien-video' style={isVisible ?
                        {display: 'flex'} :
                        {display: 'none'}}/>

                </>

            }

            {title !==
                "Au coeur de l'histoire" &&
                <img src={image} alt="" className='article2-image'/>
            }

            <div className='vieille-ville-video' style={isVisible ?
                {display: 'none'} :
                {display: 'flex'}}>
                {!isVisible &&
                    <iframe src="https://www.youtube.com/embed/V9K8UUvor7I?si=CQwxDEYp-HvzQHEP"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen
                            className="video-vieille-ville"></iframe>
                }

            </div>

            {/* Overlay */}
            {!isVisible &&
                (
                    <div
                        className="overlay"
                        onClick={handleVideo}
                    ></div>
                )}

            <div className='article2-descriptions' style={isVisible ?
                {display: 'flex'} :
                {display: 'none'}}>
                {description3 ?
                    <article className='article3-description'>{description3}</article>
                :
                    <>
                        <article className='article2-description'>{description1}</article>
                        <article className='article2-description'>{description2}</article>
                    </>
                }

            </div>
        </div>
    )
}


