import '../../Styles/lieu.scss';
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import L from "leaflet";
import {LieuxContext} from "../../Contexts/LieuxContext";


const Lieu = () => {

    const navigate = useNavigate();
    const [lieu, setLieu] = useState(null);
    const [lieuTerritoire, setLieuTerritoire] = useState(null);
    const [anecdoteHover, setAnecdoteHover] = useState(null);
    const mapRef = React.useRef(null);
    const [coords, setCoords] = useState(null);
    const expsRef = React.useRef(null);
    const placesRef = React.useRef(null);
    const [distance, setDistance] = useState(null);
    const [lieux, setLieux] = useState(null);
    const [lieuHover, setLieuHover] = useState(null);
    const [anecdoteModal, setAnecdoteModal] = useState(null);
    const uri = "https://preprodback-istorio.monolithe.studio";
    const [localisation, setLocalisation] = useState(null);
    const {typeNameToObject} = useContext(LieuxContext);
    const [ln, setLn] = useState(null);


    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setLocalisation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        });
    }, [])

    useEffect(() => {
        if (localisation?.latitude) {
            const coords = [localisation.latitude, localisation.longitude];
            const encodedCoords = encodeURIComponent(coords);
            fetch(uri+"/lieux/all/coords/" + encodedCoords, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((response) => response.json()).then((data) => {
                setLieux(data);
            })
        }
    }, [localisation]);
    const expsScrollR = () => {
        expsRef.current.scrollLeft += 325;
    }
    const expsScrollL = () => {
        expsRef.current.scrollLeft -= 325;
    }
    const lieuxScrollR = () => {
        placesRef.current.scrollLeft += 300;
    }
    const lieuxScrollL = () => {
        placesRef.current.scrollLeft -= 300;
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchLieuData = async () => {
            try {
                const lieuResponse = await fetch(uri + "/lieux/name/" + window.location.pathname.split("/")[2], {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!lieuResponse.ok) throw new Error("Failed to fetch lieu data");
                const lieuData = await lieuResponse.json();
                setLieu(lieuData);

                console.log(uri + '/lieux/territoire/place/' + window.location.pathname.split("/")[2])
                const territoireResponse = await fetch(uri + '/lieux/territoire/place/' + window.location.pathname.split("/")[2], {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (!territoireResponse.ok) throw new Error("Failed to fetch territoire data");
                const territoireData = await territoireResponse.json();
                setLieuTerritoire(territoireData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchLieuData();
    }, [window.location.pathname.split("/")[2]]);

    const getLieuTypeDetails = (type) => {
        if (type === "Architecture remarquable") {
            return {
                color: "#3CAEEE",
                svg: <svg width="87" height="66" viewBox="0 0 87 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M58.9606 11.2313H85.1968V63.6701H1.5V11.2313H8.55173H27.8855C28.5521 11.2313 29.1573 10.8416 29.4331 10.2347L33.4035 1.5H53.5748L57.4042 10.2151C57.6755 10.8326 58.2862 11.2313 58.9606 11.2313Z"
                        stroke="white" stroke-width="3"/>
                    <path
                        d="M23.8549 10.1496V5.09062C23.8549 4.98017 23.7654 4.89062 23.6549 4.89062H8.27812C8.16767 4.89062 8.07812 4.98017 8.07812 5.09062V10.1496"
                        stroke="white" stroke-width="2"/>
                    <circle cx="43.3469" cy="35.3294" r="21.2063" stroke="white" stroke-width="3"/>
                    <circle cx="43.3469" cy="35.3294" r="21.2063" stroke="white" stroke-width="3"/>
                    <circle cx="43.6349" cy="35.0334" r="14.3341" stroke="white" stroke-width="2"/>
                    <circle cx="43.6349" cy="35.0334" r="14.3341" stroke="white" stroke-width="2"/>
                    <path d="M0.867188 25.1426L25.1423 25.1426" stroke="white" stroke-width="2"/>
                    <path d="M61.5547 25.1426H84.9628" stroke="white" stroke-width="2"/>
                </svg>
            }
        } else if (type === "Château / Palais") {
            return {
                color: "#328DC0",
                svg: <svg width="64" height="74" viewBox="0 0 64 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M23.7759 72.1667H10.2771C10.001 72.1667 9.77709 71.9428 9.77709 71.6667V29.6381C9.77709 29.507 9.7256 29.3812 9.63371 29.2877L2.14339 21.6659C2.05149 21.5724 2 21.4466 2 21.3155V2.5C2 2.22386 2.22386 2 2.5 2H9.27709C9.55324 2 9.77709 2.22386 9.77709 2.5V8.88596C9.77709 9.16211 10.001 9.38596 10.2771 9.38596H16.0172C16.2934 9.38596 16.5172 9.16211 16.5172 8.88596V2.5C16.5172 2.22386 16.7411 2 17.0172 2H27.9421C28.2183 2 28.4421 2.22386 28.4421 2.5V8.88596C28.4421 9.16211 28.666 9.38596 28.9421 9.38596H35.2007C35.4769 9.38596 35.7007 9.16211 35.7007 8.88596V2.5C35.7007 2.22386 35.9246 2 36.2007 2H47.1256C47.4018 2 47.6256 2.22386 47.6256 2.5V8.88596C47.6256 9.16211 47.8495 9.38596 48.1256 9.38596H54.3842C54.6604 9.38596 54.8842 9.16211 54.8842 8.88596V2.5C54.8842 2.22386 55.1081 2 55.3842 2H61.6429C61.919 2 62.1429 2.22386 62.1429 2.5V21.3155C62.1429 21.4466 62.0914 21.5724 61.9995 21.6659L55.0276 28.7601C54.9357 28.8536 54.8842 28.9795 54.8842 29.1106V71.6667C54.8842 71.9428 54.6604 72.1667 54.3842 72.1667H39.33M23.7759 72.1667C23.7759 68.2978 23.7759 60.1381 23.7759 58.4499C23.7759 56.3396 24.8128 50.5363 31.553 50.5363C38.2931 50.5363 39.33 55.812 39.33 58.4499C39.33 60.5601 39.33 68.4737 39.33 72.1667M23.7759 72.1667H39.33"
                        stroke="white" stroke-width="3"/>
                    <rect x="26.0625" y="27.0645" width="11.0262" height="11.0262" rx="0.5" fill="white"/>
                    <rect x="26.0625" y="27.0645" width="11.0262" height="11.0262" rx="0.5" fill="white"/>
                    <rect x="26.0625" y="27.0645" width="11.0262" height="11.0262" rx="0.5" fill="white"/>
                    <path d="M39.5865 60.6406L24.5508 60.6406" stroke="white" stroke-width="1.5"/>
                </svg>
            }
        } else if (type === "Monument commémoratif") {
            return {
                color: "#80AFBA",
                svg: <svg width="94" height="104" viewBox="0 0 94 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.66406 102H92.4211" stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path d="M8.14844 94.0781L85.9402 94.0781" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path
                        d="M32.7891 93.1935V80.7152C32.7891 80.66 32.8338 80.6152 32.8891 80.6152H61.861C61.9162 80.6152 61.961 80.66 61.961 80.7152V93.1935"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path
                        d="M37.3164 80.6142L38.6122 10.2171C38.6127 10.1907 38.6236 10.1656 38.6425 10.1472L46.9707 2.06755C47.0095 2.02992 47.0712 2.02992 47.11 2.06755L55.4382 10.1472C55.4572 10.1656 55.4681 10.1907 55.4686 10.2171L56.7643 80.6142"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path d="M47.043 80.6138V33.4453" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M38.3477 14.5781H48.0716" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            }
        } else if (type === "Patrimoine industriel") {
            return {
                color: "#5A3E24",
                svg: <svg width="73" height="100" viewBox="0 0 73 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M27.7148 92.0712H71.082C71.1372 92.0712 71.182 92.0294 71.182 91.9742C71.182 83.6535 71.182 65.9716 71.182 61.0233C71.182 56.0978 59.4515 46.6956 53.4369 42.5011C53.3707 42.4549 53.2837 42.5049 53.2837 42.5856V57.7171C53.2837 57.7997 53.1891 57.8467 53.1233 57.7967L32.9891 42.5163C32.9232 42.4663 32.8286 42.5133 32.8286 42.596V57.9185"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <mask id="path-2-inside-1_1882_213" fill="white">
                        <rect x="38.8828" y="65.3691" width="18.6288" height="18.6288" rx="0.5"/>
                    </mask>
                    <rect x="38.8828" y="65.3691" width="18.6288" height="18.6288" rx="0.5" stroke="white"
                          stroke-width="4" mask="url(#path-2-inside-1_1882_213)"/>
                    <rect x="38.8828" y="65.3691" width="18.6288" height="18.6288" rx="0.5" stroke="white"
                          stroke-width="4" mask="url(#path-2-inside-1_1882_213)"/>
                    <path
                        d="M26.4492 5.13672C29.3705 16.8218 37.789 13.1147 38.774 12.6357C38.8265 12.6101 38.8844 12.6247 38.9093 12.6775C39.4677 13.862 45.7744 26.2319 59.981 13.2092"
                        stroke="white" stroke-linecap="round"/>
                    <path
                        d="M5.96679 14.546L1.63045 98.1754C1.62749 98.2326 1.67306 98.2806 1.73032 98.2806H18.2856C18.3428 98.2806 18.3884 98.2326 18.3854 98.1754L14.0491 14.546C14.0463 14.4928 14.0024 14.4512 13.9492 14.4512H6.06666C6.01344 14.4512 5.96955 14.4928 5.96679 14.546Z"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path d="M10.3125 5.13277C14.0358 2.02802 23.3502 -4.18173 31.4251 9.47938" stroke="white"
                          stroke-linecap="round"/>
                    <path
                        d="M6.5 26.8633H14.407C14.4594 26.8633 14.5029 26.9038 14.5067 26.9561L16.5162 54.6992C16.5204 54.7571 16.4745 54.8064 16.4165 54.8064H4.19791C4.14544 54.8064 4.1019 54.847 4.09816 54.8993L1.00765 98.1664C1.00352 98.2243 1.04936 98.2735 1.1074 98.2735H10.2144C10.2696 98.2735 10.3144 98.2288 10.3144 98.1735V88.9592"
                        stroke="white" stroke-width="2" stroke-linecap="round"/>
                    <path
                        d="M29.5664 6.99461C35.776 2.02702 43.8485 8.23664 43.8485 14.446M45.0904 11.3414C50.058 8.23664 58.7515 5.13184 60.6143 11.3414"
                        stroke="white" stroke-linecap="round"/>
                </svg>
            }
        } else if (type === "Patrimoine militaire") {
            return {
                color: "#939945",
                svg: <svg width="99" height="60" viewBox="0 0 99 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.4295 8.95581C15.6487 8.95581 -1.28476 25.7883 19.8928 40.3782M44.7785 7.21686L93.4184 2.00972C93.4698 2.00421 93.517 2.03883 93.5271 2.08954L96.9813 19.3606C96.991 19.409 96.964 19.4572 96.9177 19.4741L68.6868 29.8232"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path
                        d="M34.2341 37.4004C31.9081 37.4004 26.5329 37.4004 24.7884 37.4004C18.1584 37.4003 19.555 48.1031 16.0661 49.1734C13.7401 49.8869 7.34375 48.3176 7.34375 53.9896C7.34375 57.4076 11.3979 58.0896 12.5701 58.2696C12.5751 58.2704 12.5797 58.2707 12.5847 58.2707C14.7153 58.271 17.237 58.4129 19.555 58.2707C28.2773 57.7356 24.2069 45.9626 29.4403 45.9626C33.627 45.9626 33.7393 45.9626 34.5146 45.9626"
                        stroke="white" stroke-width="2" stroke-linecap="round"/>
                    <path d="M23.4766 10.6934L28.2587 36.7777" stroke="white" stroke-width="2"
                          stroke-linecap="round"/>
                    <path d="M76.0664 3.40039L80.4274 25.4769" stroke="white" stroke-width="2"
                          stroke-linecap="round"/>
                    <circle cx="52.3466" cy="38.9794" r="18.038" stroke="white" stroke-width="3"/>
                    <circle cx="52.3447" cy="38.9833" r="8.17122" stroke="white" stroke-width="1.8"/>
                    <path d="M52.3398 22.2363V30.6098" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
                    <path d="M52.3398 47.3535V55.7269" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
                    <path d="M34.7812 38.9512L43.9101 38.9507" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <path d="M60.7344 38.9883H69.1078" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
                    <path d="M39.7852 26.4238L46.0821 32.8651" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <path d="M39.7852 51.5414L46.0821 45.0391" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <path d="M64.9114 51.5414L58.6875 45.0391" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <circle cx="37.8244" cy="8.52168" r="3.04317" fill="white"/>
                    <path d="M64.1947 27.125L58.5312 32.2252" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <circle cx="5.58229" cy="27.6311" r="4.83229" stroke="white" stroke-width="1.5"/>
                </svg>
            }
        } else if (type === "Patrimoine naturel") {
            return {
                color: "#1EB749",
                svg: <svg width="85" height="90" viewBox="0 0 85 90" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M26.332 48.1457C28.3705 50.8778 33.6081 57.297 34.751 59.7363C39.9961 70.9307 36.5162 79.3387 30.7932 87.6992C30.608 87.9698 30.394 88.1451 30.7215 88.1604C33.8106 88.305 54.8666 88.903 54.3668 88.1308C45.3178 74.1522 46.9834 65.2501 50.1441 59.7363C50.2299 59.5865 50.3168 59.4393 50.4047 59.2946C53.0556 54.9262 55.7923 53.9132 55.7923 51.9931M32.8768 48.3793C35.2635 49.4118 35.7409 53.0256 37.6506 53.0256C40.0377 53.0256 37.4559 36.3077 39.8497 33.7266M45.2567 36.6103C46.1432 39.007 45.7666 51.9931 47.1989 53.0256C49.185 54.4574 53.6837 45.2619 56.0708 45.2619"
                        stroke="white" stroke-width="2"/>
                    <path
                        d="M34.4423 64.895C27.3532 67.4554 20.0265 67.19 16.886 59.7968C16.8543 59.7223 16.7787 59.6717 16.6978 59.671C6.56071 59.5847 2 49.8652 2 44.7388C2 39.5895 6.60314 34.4403 9.16071 31.8656C11.7183 29.291 11.1968 31.8156 10.6953 29.291C9.16072 21.5671 9.16072 18.9925 14.2759 13.8433C18.3198 9.77243 24.5062 9.20872 28.0868 11.7833C29.6213 12.8868 29.8524 11.3499 30.6444 8.69402C32.1789 3.54817 39.3932 2 42.4099 2C51.3043 2 53.492 7.83921 55.1971 11.272C55.1971 12.1302 55.7184 12.502 57.7547 11.272C60.3123 9.72726 67.985 8.69741 73.1009 16.4177C75.6588 20.2778 75.2737 27.1064 73.1001 29.2944C70.5426 31.869 78.2153 31.869 80.7729 37.0183C83.3304 42.1675 84.3915 45.1847 80.7729 52.466C78.2153 57.6123 70.5426 60.1899 67.985 60.1899C65.4274 60.1899 68.4965 62.7645 63.3814 65.3391C60.1462 66.9675 61.7958 66.8839 57.7547 66.8839C53.7135 66.8839 52.6724 66.0531 50.1026 64.895"
                        stroke="white" stroke-width="3"/>
                </svg>
            }
        } else if (type === "Patrimoine religieux") {
            return {
                color: "#A04DA8",
                svg: <svg width="76" height="91" viewBox="0 0 76 91" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.7079 45.7559H1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M13.5119 30.0279L6.98047 25.1895" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M22.763 17.3339L18.4102 10.0762" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M66.2921 45.7578H75" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M62.4881 30.0298L69.0195 25.1914" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M53.237 17.328L57.5898 10.0703" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M38 10.0721V1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <path
                        d="M6.98828 73.2649C6.98828 73.2649 14.294 71.2784 15.1137 70.8772C16.6372 70.1315 14.5692 60.8242 15.1137 54.4296C15.6214 48.4666 22.8691 29.7711 28.3139 20.1757C28.3163 20.1714 28.3184 20.167 28.3201 20.1624C29.9346 15.9932 31.1569 15.4202 32.3801 15.9937C33.6041 16.5676 34.9193 17.1865 34.4115 20.1696L29.8409 35.0847"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path
                        d="M6.98828 88.7749C17.145 88.7749 34.4115 82.8092 34.4115 64.9123C34.4115 55.9638 31.3644 56.5603 31.3644 50.5947C31.3644 47.0153 32.3801 41.0496 32.3801 38.0668C32.3801 36.8248 31.3644 35.084 29.8409 35.084C27.8197 35.084 26.804 37.0528 26.794 38.0519C26.7939 38.062 26.7924 38.0717 26.7893 38.0814L22.8723 50.5947"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path
                        d="M69.0508 73.4465C69.0508 73.4465 61.7451 71.4601 60.9254 71.0589C59.4019 70.3132 61.4699 63.284 60.9254 56.8894C60.4177 50.9264 54.9468 32.2306 47.7262 20.3586C47.7231 20.3535 47.7205 20.3481 47.7184 20.3426C46.1042 16.1747 44.8821 15.6019 43.6589 16.1753C42.435 16.7492 41.1198 17.3682 41.6276 20.3512L46.1981 35.2663"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path
                        d="M69.0508 88.9565C58.894 88.9565 41.6276 82.9909 41.6276 65.0939C41.6276 56.1454 44.6746 56.742 44.6746 50.7763C44.6746 47.1969 43.6589 41.2313 43.6589 38.2485C43.6589 37.0064 44.6746 35.2656 46.1981 35.2656C48.2211 35.2656 49.2367 37.2378 49.2451 38.2361C49.2452 38.2445 49.2462 38.2526 49.2483 38.2607L52.4779 50.7763"
                        stroke="white" stroke-width="3" stroke-linecap="round"/>
                </svg>
            }
        } else if (type === "Pont & Ouvrage d’art") {
            return {
                color: "#83735B",
                svg: <svg width="137" height="77" viewBox="0 0 137 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 55.1602L134.917 55.1602" stroke="white" stroke-width="3" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path
                        d="M101.687 2V8.64583M101.687 75.1041V8.64583M35.2291 2V8.64583M35.2291 75.1041V8.64583M35.2291 8.64583C28.5833 18.6146 11.9687 35.2291 2 38.552M101.687 8.64583C105.01 15.2917 124.948 38.552 134.917 38.552"
                        stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M35.2188 5.31641C55.1562 21.931 81.7395 21.931 101.677 5.31641" stroke="white"
                          stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M68.4648 55.1731V18.6211" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M53.1797 55.1601V15.2852" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M18.6211 55.1732V28.5898" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M119.621 55.1732V28.5898" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M83.75 55.1601V15.2852" stroke="white" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
            }
        } else if (type === "Site archéologique") {
            return {
                color: "#DF8C0F",
                svg: <svg width="79" height="84" viewBox="0 0 79 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.8986 3.02734C7.65095 13.815 66.2111 36.9315 61.5904 19.4657" stroke="white"
                          stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M29.1172 65.6895H66.0265" stroke="white" stroke-width="2.8" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path
                        d="M66.6154 65.6897C66.6154 70.8982 74.1154 74.5437 77 74.5437M2 74.5442C4.84736 74.5442 10.5053 70.9916 10.651 65.8905C10.6541 65.7801 10.7434 65.6895 10.8538 65.6895H19.3077"
                        stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                        d="M77 74.5501C77 76.5574 77 80.3351 77 82.1612C77 82.2717 76.9105 82.3604 76.8 82.3604H2.2C2.08954 82.3604 2 82.2708 2 82.1604V74.5488"
                        stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.2617 64.1266V4.22852" stroke="white" stroke-width="3" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M62.5781 65.1726V24.0254" stroke="white" stroke-width="3" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path d="M48.1421 9.44003C39.4883 4.23149 22.8174 -0.207672 15.2617 3.19011" stroke="white"
                          stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M62.5778 25.0636C62.5778 19.855 62.5778 17.2508 56.8086 14.6465" stroke="white"
                          stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16.4102 74.0273H71.2178" stroke="white" stroke-width="1.3" stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path
                        d="M25.3516 55.5721C25.3516 55.9863 25.6873 56.3221 26.1016 56.3221C26.5158 56.3221 26.8516 55.9863 26.8516 55.5721H25.3516ZM25.3516 16.3828L25.3516 55.5721H26.8516L26.8516 16.3828H25.3516Z"
                        fill="white"/>
                    <path
                        d="M38.2227 55.5726C38.2227 55.9868 38.5584 56.3226 38.9727 56.3226C39.3869 56.3226 39.7227 55.9868 39.7227 55.5726H38.2227ZM38.2227 22.5488V55.5726H39.7227V22.5488H38.2227Z"
                        fill="white"/>
                    <path
                        d="M51.6016 55.5737C51.6016 55.9879 51.9374 56.3237 52.3516 56.3237C52.7658 56.3236 53.1016 55.9878 53.1016 55.5736L51.6016 55.5737ZM51.5995 26.1094L51.6016 55.5737L53.1016 55.5736L53.0995 26.1093L51.5995 26.1094Z"
                        fill="white"/>
                </svg>
            }
        } else if (type === "Street Art") {
            return {
                svg: <svg width="78" height="85" viewBox="0 0 78 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.00391 36.7773H15.9101" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M23.3711 15.9062H38.2773" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    <path d="M14.4102 15.9062H14.907" stroke="white" stroke-width="1.8" stroke-linecap="round"/>
                    <path d="M56.6523 15.9062H65.5961" stroke="white" stroke-width="3" stroke-linecap="round"/>
                    <path d="M33.3047 27.166L48.8593 19.3887" stroke="white" stroke-width="2"
                          stroke-linecap="round"/>
                    <path d="M33.3047 4.47266L47.9898 11.8168" stroke="white" stroke-width="2"
                          stroke-linecap="round"/>
                    <path d="M26.3438 30.8105L26.7763 30.566" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <path d="M26.3438 1L26.7763 1.24452" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <path d="M13.4336 39.2598L13.4336 49.1972" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M67.5898 36.7773L67.5898 46.7148" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <path d="M56.375 15.9062L55.6351 24.6762" stroke="white" stroke-width="3"
                          stroke-linecap="round"/>
                    <path d="M67.4766 24.6758L66.3745 15.9373" stroke="white" stroke-width="3"
                          stroke-linecap="round"/>
                    <path d="M67.5898 54.166L67.5898 56.6504" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <path d="M63.6172 34.293L63.6172 39.2617" stroke="white" stroke-width="1.8"
                          stroke-linecap="round"/>
                    <path d="M35.7812 49.1973L1.00011 49.1973" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M35.7812 64.1035L1.00011 64.1035" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M35.7812 76.5215L1.00011 76.5215" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M20.8789 64.1035L20.8789 74.041" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M5.96875 49.1973L5.96875 64.1035" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <path d="M28.3359 49.1973L28.3359 64.1035" stroke="white" stroke-width="1.5"
                          stroke-linecap="round"/>
                    <mask id="path-21-inside-1_1892_552" fill="white">
                        <rect x="44.7109" y="31.9609" width="33.2905" height="52.1717" rx="2"/>
                    </mask>
                    <rect x="44.7109" y="31.9609" width="33.2905" height="52.1717" rx="2" stroke="white"
                          stroke-width="6" mask="url(#path-21-inside-1_1892_552)"/>
                    <path
                        d="M75.0421 34.2851C75.0421 28.7968 69.0357 24.3477 61.6265 24.3477C54.2173 24.3477 48.2109 28.7968 48.2109 34.2851"
                        stroke="white" stroke-width="3"/>
                </svg>
            }
        }
    }

    useEffect(() => {
        if (lieu) {
            document.title = "Istor.io | " + lieu.name;
            fetch("https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAH44OK_htVCq6fNTLKD-m1DLUQ3x_7YY8&address=" + lieu?.adress, {
                method: "GET",
            }).then(response => response.json())
                .then(data => {
                    if (data.results && data.results.length > 0) {
                        setCoords([
                            parseFloat(data.results[0].geometry.location.lat),
                            parseFloat(data.results[0].geometry.location.lng),
                        ]);
                        mapRef?.current?.setView([parseFloat(data.results[0].geometry.location.lat), parseFloat(data.results[0].geometry.location.lng)], 15);
                    }
                })
                .catch(error => console.error("Failed to fetch geocode data", error));
        }
    }, [ln]);

    useEffect(() => {
        if (lieu) {
            setLn(lieu?.name)
        }
    }, [lieu]);


    const getDistance = (start, end) => {
        const R = 6371e3; // metres
        const φ1 = start.latitude * Math.PI / 180; // φ, λ in radians
        const φ2 = end.latitude * Math.PI / 180;
        const Δφ = (end.latitude - start.latitude) * Math.PI / 180;
        const Δλ = (end.longitude - start.longitude) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    useEffect(() => {
        if (localisation && coords) {
            setDistance(
                getDistance(
                    {
                        latitude: localisation.latitude,
                        longitude: localisation.longitude,
                    },
                    {
                        latitude: coords[0],
                        longitude: coords[1],
                    },
                ),
            )
        }
    }, [localisation, coords]);


    return (
        <>
            {
                anecdoteModal !== null &&
                <>
                    <div onClick={() => setAnecdoteModal(null)} style={{
                        position: "fixed",
                        zIndex: "600",
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        cursor: "pointer",
                        boxSizing: "border-box",
                    }}></div>
                    <div style={{
                        zIndex: "601",
                        position: "fixed",
                        transform: "translate(-50%, -50%)",
                        top: "50%",
                        left: "50%",
                        boxSizing: "border-box",
                    }}>
                        <div className="lieu-modal">
                            <div style={{
                                marginBottom: "26px",
                                fontSize: "20px",
                                color: "#102E3E",
                                fontWeight: "bold",
                                boxSizing: "border-box",
                            }}>{
                                lieu?.anecdotes[anecdoteModal]?.title
                            }</div>
                            <div style={{
                                marginBottom: "38px",
                                fontSize: "14px",
                                color: "#858585",
                                boxSizing: "border-box",
                            }}>{
                                lieu?.anecdotes[anecdoteModal]?.description
                            }</div>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                boxSizing: "border-box",
                            }}>
                                <img style={{
                                    width: "300px",
                                    height: "200px",
                                    borderRadius: "14px",
                                    objectFit: "cover",
                                }} src={uri+"/files/"+lieu?.anecdotes[anecdoteModal]?.img} alt="image"/>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="lieu">
                <div className="lieu-header">
                    <div className="lieu-header-left">
                        <img style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "20px 0 0 20px",
                        }} src={
                            lieu ? uri+"/files/" + lieu?.imgs[0] : ""
                        } alt="illustration"/>
                        <div style={{
                            width: "100%",
                            height: "50%",
                            display: "flex",
                            flexDirection: "column",
                            bottom: "0",
                            borderRadius: "0 0 0 20px",
                            background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)",
                            position: "absolute",
                            color: "white",
                            justifyContent: "flex-end",
                            paddingLeft: "28px",
                            paddingBottom: "23px",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                fontSize: "28px",
                                fontWeight: "bold",
                                marginBottom: "3px",
                                boxSizing: "border-box",
                            }}>{lieu?.name}</div>
                            <div style={{
                                width: "50px",
                                height: "19px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "black",
                                backgroundColor: "#EBEBEB",
                                border: "1px solid #D8D8D8",
                                borderRadius: "8px",
                                fontSize: "10px",
                                fontWeight: "bold",
                                boxSizing: "border-box",
                            }}>
                                {
                                    distance ?distance > 1000 ? (distance / 1000).toFixed(1) + " km" : distance.toFixed(0) + " m" : ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className="lieu-header-right" style={{
                        background: lieu?.type === "Street Art" ? "linear-gradient(180deg, #7587DD 0%, #932834 100%)" : getLieuTypeDetails(lieu?.type)?.color,
                    }}>
                        {getLieuTypeDetails(lieu?.type)?.svg}
                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                            boxSizing: "border-box",
                        }}>
                            {lieu?.type}
                        </div>
                    </div>
                </div>
                <div className="lieu-contenu-row">
                    <div className="lieu-contenu-left">
                        <div style={{
                            width: "100%",
                            borderRadius: "20px",
                            padding: "25px",
                            backgroundColor: "white",
                            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
                            marginBottom: "40px",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#102E3E",
                                marginBottom: "20px",
                                boxSizing: "border-box",
                            }}>À propos</div>
                            <textPath style={{
                                fontSize: "14px",
                                color: "#858585",
                            }}>
                                {lieu?.description}
                            </textPath>
                        </div>
                        { lieu?.anecdotes.length > 0 &&
                        <div style={{
                            width: "100%",
                            borderRadius: "20px",
                            padding: "25px",
                            backgroundColor: "white",
                            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
                            marginBottom: "40px",
                            display: "flex",
                            flexDirection: "column",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#102E3E",
                                marginBottom: "17px",
                                boxSizing: "border-box",
                            }}>Anecdotes
                            </div>
                            {
                                lieu?.anecdotes.map((anecdote, index) => {
                                    return <div onMouseLeave={() => setAnecdoteHover(null)}
                                                onMouseEnter={() => setAnecdoteHover(index)}
                                                onClick={() => setAnecdoteModal(index)}
                                                key={index} style={{
                                        marginBottom: index < lieu.anecdotes.length -1 ? "10px" : "",
                                        width: "100%",
                                        height: "123px",
                                        backgroundColor: anecdoteHover === index ? "#E8EDF2" : "",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        display: "flex",
                                        cursor: "pointer",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        boxSizing: "border-box",
                                    }}>
                                        <img style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "14px",
                                            marginRight: "27px",
                                            objectFit: "cover",
                                        }} src={uri+"/files/"+anecdote.img} alt="illu"/>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            boxSizing: "border-box",
                                        }}>
                                            <div style={{
                                                fontSize: "10px",
                                                fontWeight: "bold",
                                                paddingLeft: "9px",
                                                paddingRight: "9px",
                                                height: "19px",
                                                borderRadius: "8px",
                                                backgroundColor: "#EBEBEB",
                                                color: "black",
                                                border: "2px solid #D8D8D8",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginBottom: "6px",
                                                boxSizing: "border-box",
                                            }}>{
                                                anecdote.type === 1 ? "“On sait que...”" :
                                                    anecdote.type === 2 ? "“On raconte que...”" :
                                                        anecdote.type === 3 ? "Sources non vérifiées / vérifiables" : anecdote.type
                                            }</div>
                                            <div style={{
                                                fontSize: "15px",
                                                color: "black",
                                                fontWeight: "bold",
                                                boxSizing: "border-box",
                                            }}>{anecdote.title}</div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>}
                        <div style={{
                            width: "100%",
                            borderRadius: "20px",
                            padding: "25px",
                            backgroundColor: "white",
                            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
                            marginBottom: "40px",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#102E3E",
                                marginBottom: "24px",
                                boxSizing: "border-box",
                            }}>Découvrez le lieu en images
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                width: "100%",
                                boxSizing: "border-box",
                            }}>
                                {
                                    lieu?.imgs.map((img, index) => {
                                        return <div key={index} style={{
                                            display: index < 5 ? "" : "none",
                                            width: "240px",
                                            cursor: "pointer",
                                            height: "200px",
                                            borderRadius: "14px",
                                            marginLeft: index === 0 || index === 3 ? "5px" : "0",
                                            marginRight: index === 2 ? "5px" : "10px",
                                            marginBottom: index < 3 ? "10px" : "",
                                            objectFit: "cover",
                                            backgroundImage: `url(${uri}/files/${img})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            boxSizing: "border-box",
                                        }}></div>
                                    })
                                }
                                {
                                    lieu?.imgs.length > 5 &&
                                    <div style={{
                                        backgroundColor: "#797979",
                                        width: "240px",
                                        cursor: "pointer",
                                        height: "200px",
                                        borderRadius: "14px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                        fontSize: "40px",
                                        fontWeight: "bold",
                                        boxSizing: "border-box",
                                    }}>
                                        +{lieu?.imgs.length - 5}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="lieu-contenu-right">
                        <div style={{
                            width: "100%",
                            borderRadius: "20px",
                            padding: "25px",
                            backgroundColor: "white",
                            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
                            marginBottom: "30px",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#102E3E",
                                marginBottom: "20px",
                                boxSizing: "border-box",
                            }}>Informations pratiques
                            </div>
                            { coords &&
                            <MapContainer center={coords} zoom={15} ref={mapRef} style={{
                                width: "100%",
                                height: "200px",
                                marginBottom: "18px",
                                zIndex: "0",
                            }}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                                />
                                <Marker position={coords} icon={
                                    L.icon({
                                        iconUrl: typeNameToObject(lieu.type).icon,
                                        iconSize: [30, 30],
                                        iconAnchor: [15, 0],
                                        popupAnchor: [0, -30],
                                    })
                                } />
                            </MapContainer>
                            }
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "12px",
                                fontSize: "14px",
                                color: "#102E3E",
                                boxSizing: "border-box",
                            }}>
                                <svg style={{
                                    marginRight: "10px",
                                }} width="32" height="32" viewBox="0 0 32 32" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_1921_183)">
                                        <circle cx="16" cy="15" r="11.5" fill="#F4F4F7" stroke="white"/>
                                    </g>
                                    <path
                                        d="M21 13.6C21 10.8583 19.6364 8 16 8C12.3636 8 11 10.8 11 13.6C11 16.3563 14.2301 19.8663 15.9208 21.9042C15.9619 21.9538 16.0365 21.9523 16.0759 21.9014C17.7657 19.7182 21 16.3006 21 13.6Z"
                                        fill="#979FB0" stroke="#37466A"/>
                                    <circle cx="16" cy="13" r="2.5" fill="#F4F4F7" stroke="#37466A"/>
                                    <defs>
                                        <filter id="filter0_d_1921_183" x="0" y="0" width="32" height="32"
                                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                           result="hardAlpha"/>
                                            <feOffset dy="1"/>
                                            <feGaussianBlur stdDeviation="2"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix"
                                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                     result="effect1_dropShadow_1921_183"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1921_183"
                                                     result="shape"/>
                                        </filter>
                                    </defs>
                                </svg>
                                {lieu?.adress}
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "12px",
                                fontSize: "14px",
                                color: "#102E3E",
                                boxSizing: "border-box",
                            }}>
                                <svg style={{
                                    marginRight: "10px",
                                }} width="32" height="32" viewBox="0 0 32 32" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g filter="url(#filter0_d_1921_145)">
                                        <circle cx="16" cy="15" r="11.5" fill="#F4F4F7" stroke="white"/>
                                    </g>
                                    <path d="M16 15L20 18" stroke="#37466A" stroke-linecap="round"/>
                                    <path d="M19 10L16 15" stroke="#37466A" stroke-opacity="0.5" stroke-width="0.5"
                                          stroke-linecap="round"/>
                                    <path d="M16 15V11" stroke="#37466A" stroke-linecap="round"/>
                                    <circle cx="16" cy="15" r="7.5" stroke="#37466A"/>
                                    <path d="M16 9L16 8" stroke="#37466A" stroke-opacity="0.5" stroke-linecap="round"/>
                                    <path d="M22 15H23" stroke="#37466A" stroke-opacity="0.5" stroke-linecap="round"/>
                                    <path d="M9 15H10" stroke="#37466A" stroke-opacity="0.5" stroke-linecap="round"/>
                                    <path d="M16 21L16 22" stroke="#37466A" stroke-opacity="0.5"
                                          stroke-linecap="round"/>
                                    <defs>
                                        <filter id="filter0_d_1921_145" x="0" y="0" width="32" height="32"
                                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                            <feColorMatrix in="SourceAlpha" type="matrix"
                                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                           result="hardAlpha"/>
                                            <feOffset dy="1"/>
                                            <feGaussianBlur stdDeviation="2"/>
                                            <feComposite in2="hardAlpha" operator="out"/>
                                            <feColorMatrix type="matrix"
                                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                            <feBlend mode="normal" in2="BackgroundImageFix"
                                                     result="effect1_dropShadow_1921_145"/>
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1921_145"
                                                     result="shape"/>
                                        </filter>
                                    </defs>
                                </svg>
                                <div style={{
                                    color: lieu?.hourly === 2 ? "#31BC1A" : "red",
                                    boxSizing: "border-box",
                                }}>
                                    {
                                        lieu?.hourly === 2 ? "Ouvert" :
                                            lieu?.hourly === 3 ? "Fermé" : "Heures d’ouverture non renseignées"
                                    }
                                </div>
                            </div>
                            { lieu?.pmr === true &&
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: lieu?.bikePark === true || lieu?.carBike === true || lieu?.publicTransports.length > 0 ? "12px" : "",
                                    fontSize: "14px",
                                    color: "#102E3E",
                                    boxSizing: "border-box",
                                }}>
                                    <svg style={{
                                        marginRight: "10px",
                                    }} width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d_1921_172)">
                                            <circle cx="16" cy="15" r="12" fill="#F4F4F7"/>
                                            <circle cx="16" cy="15" r="11.5" stroke="white"/>
                                        </g>
                                        <path
                                            d="M17.9 19.0331C17.9 20.9211 16.2708 22.4997 14.2 22.4997C12.1292 22.4997 10.5 20.9211 10.5 19.0331C10.5 17.145 12.1292 15.5664 14.2 15.5664C16.2708 15.5664 17.9 17.145 17.9 19.0331Z"
                                            fill="#979FB0" stroke="#37466A"/>
                                        <path
                                            d="M22 21.8668L21.7499 19.1276C21.6318 17.834 20.4479 16.9074 19.1638 17.1035V17.1035C17.7986 17.312 16.5684 16.2554 16.5684 14.8744V14.2767C16.5684 12.467 15.1014 11 13.2917 11V11C13.2869 11 13.2821 11.0016 13.2783 11.0046L12 12.0001M13.9 17.4999L10.0785 13.9439C10.036 13.9044 10.036 13.8373 10.0783 13.7977L12 12.0001M12 12.0001L13.2842 15.5001"
                                            stroke="#37466A" stroke-linecap="round"/>
                                        <circle cx="14" cy="8.2002" r="1.5" stroke="#37466A"/>
                                        <path d="M13.957 11L14 9.50024" stroke="#37466A"/>
                                        <defs>
                                            <filter id="filter0_d_1921_172" x="0" y="0" width="32" height="32"
                                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix"
                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                               result="hardAlpha"/>
                                                <feOffset dy="1"/>
                                                <feGaussianBlur stdDeviation="2"/>
                                                <feComposite in2="hardAlpha" operator="out"/>
                                                <feColorMatrix type="matrix"
                                                               values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix"
                                                         result="effect1_dropShadow_1921_172"/>
                                                <feBlend mode="normal" in="SourceGraphic"
                                                         in2="effect1_dropShadow_1921_172" result="shape"/>
                                            </filter>
                                        </defs>
                                    </svg>
                                    Accès PMR
                                </div>
                            }
                            {
                                ((lieu?.bikePark === true || lieu?.carBike === true) || lieu?.publicTransports.length > 0) &&
                                <>
                                    <div style={{
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor: "#E6E6E6",
                                        marginBottom: "12px",
                                        boxSizing: "border-box",
                                    }}></div>
                                    { lieu?.carBike === true &&
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginBottom: lieu?.carBike === true || lieu?.publicTransports.length > 0 ? "12px" : "0",
                                            fontSize: "14px",
                                            color: "#102E3E",
                                            boxSizing: "border-box",
                                        }}>
                                            <svg style={{
                                                marginRight: "10px",
                                            }} width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_1921_69)">
                                                    <circle cx="16" cy="15" r="11.5" fill="#F4F4F7" stroke="white"/>
                                                </g>
                                                <rect x="9.5" y="8.5" width="13" height="13" rx="1.5" stroke="#37466A"/>
                                                <path
                                                    d="M14.25 18.6748V11.3373C14.25 11.2821 14.2962 11.2373 14.3515 11.2374C17.3319 11.2448 18.1875 11.5853 18.1875 13.3158C18.1875 15.0659 17.3125 15.5033 14.6875 15.5033"
                                                    stroke="#37466A" stroke-width="2" stroke-linecap="round"/>
                                                <defs>
                                                    <filter id="filter0_d_1921_69" x="0" y="0" width="32" height="32"
                                                            filterUnits="userSpaceOnUse"
                                                            color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                       result="hardAlpha"/>
                                                        <feOffset dy="1"/>
                                                        <feGaussianBlur stdDeviation="2"/>
                                                        <feComposite in2="hardAlpha" operator="out"/>
                                                        <feColorMatrix type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                                 result="effect1_dropShadow_1921_69"/>
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                                 in2="effect1_dropShadow_1921_69" result="shape"/>
                                                    </filter>
                                                </defs>
                                            </svg>
                                            Parking voiture
                                        </div>
                                    }
                                    {lieu?.bikePark === true &&
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginBottom: lieu?.publicTransports.length > 0 ? "12px" : "0",
                                            fontSize: "14px",
                                            color: "#102E3E",
                                            boxSizing: "border-box",
                                        }}>
                                            <svg style={{
                                                marginRight: "10px",
                                            }} width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_1921_53)">
                                                    <circle cx="16" cy="15" r="11.5" fill="#F4F4F7" stroke="white"/>
                                                </g>
                                                <circle cx="3.1871" cy="3.1871" r="2.6871"
                                                        transform="matrix(-1 0 0 1 24.5 13.541)" stroke="#37466A"/>
                                                <path
                                                    d="M21.3086 16.7283L18.853 10.9402C18.8387 10.9064 18.8441 10.8674 18.867 10.8387L19.508 10.0375C19.5269 10.0138 19.5557 10 19.586 10H20.6003"
                                                    stroke="#37466A" stroke-linecap="round"/>
                                                <path
                                                    d="M19.3672 12.3022L15.2386 16.9469C15.1862 17.0058 15.0894 16.9837 15.0677 16.9079L13.3471 10.8857"
                                                    stroke="#37466A" stroke-linecap="round"/>
                                                <path
                                                    d="M19.1914 11.7705L13.91 13.0028C13.8902 13.0075 13.8723 13.018 13.8586 13.0331L10.5154 16.7282"
                                                    stroke="#37466A" stroke-linecap="round"/>
                                                <path
                                                    d="M12.5533 10.8407L14.2937 10.5507C14.3547 10.5405 14.4014 10.5882 14.3653 10.6383C14.23 10.8266 13.8171 11.0934 13.5249 11.2395C13.3035 11.3502 12.8516 11.1798 12.5237 11.0155C12.4406 10.9739 12.4616 10.856 12.5533 10.8407Z"
                                                    fill="#D9D9D9" stroke="#37466A" stroke-linecap="round"/>
                                                <circle cx="3.1871" cy="3.1871" r="2.6871"
                                                        transform="matrix(-1 0 0 1 13.875 13.541)" stroke="#37466A"/>
                                                <circle cx="0.885306" cy="0.885306" r="0.885306"
                                                        transform="matrix(-1 0 0 1 16 16.0205)" fill="#37466A"/>
                                                <defs>
                                                    <filter id="filter0_d_1921_53" x="0" y="0" width="32" height="32"
                                                            filterUnits="userSpaceOnUse"
                                                            color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                       result="hardAlpha"/>
                                                        <feOffset dy="1"/>
                                                        <feGaussianBlur stdDeviation="2"/>
                                                        <feComposite in2="hardAlpha" operator="out"/>
                                                        <feColorMatrix type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                                 result="effect1_dropShadow_1921_53"/>
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                                 in2="effect1_dropShadow_1921_53" result="shape"/>
                                                    </filter>
                                                </defs>
                                            </svg>
                                            Parking vélo
                                        </div>
                                    }
                                    {lieu?.publicTransports.length > 0 &&
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontSize: "14px",
                                            color: "#102E3E",
                                            boxSizing: "border-box",
                                        }}>
                                            <svg style={{
                                                marginRight: "10px",
                                            }} width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_1921_35)">
                                                    <circle cx="16" cy="15" r="11.5" fill="#F4F4F7" stroke="white"/>
                                                </g>
                                                <rect x="11" y="9.2998" width="10.0024" height="11.0027" rx="2.5"
                                                      stroke="#37466A"/>
                                                <path d="M11 11.3008H20.5025" stroke="#37466A" stroke-linecap="round"/>
                                                <path d="M11 15.3018H20.5025" stroke="#37466A" stroke-linecap="round"/>
                                                <ellipse cx="13.5" cy="17.8027" rx="1" ry="1" fill="#933232"/>
                                                <ellipse cx="18.5039" cy="17.8027" rx="1" ry="1" fill="#933232"/>
                                                <line x1="12.5" y1="7.5" x2="19.5012" y2="7.5" stroke="#37466A"/>
                                                <path
                                                    d="M11.084 22.8262C10.9308 23.0559 10.9929 23.3664 11.2226 23.5195C11.4524 23.6727 11.7628 23.6106 11.916 23.3809L11.084 22.8262ZM13.084 19.8262L11.084 22.8262L11.916 23.3809L13.916 20.3809L13.084 19.8262Z"
                                                    fill="#37466A"/>
                                                <path
                                                    d="M20.916 22.8262C21.0692 23.0559 21.0071 23.3664 20.7774 23.5195C20.5476 23.6727 20.2372 23.6106 20.084 23.3809L20.916 22.8262ZM18.916 19.8262L20.916 22.8262L20.084 23.3809L18.084 20.3809L18.916 19.8262Z"
                                                    fill="#37466A"/>
                                                <defs>
                                                    <filter id="filter0_d_1921_35" x="0" y="0" width="32" height="32"
                                                            filterUnits="userSpaceOnUse"
                                                            color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                       result="hardAlpha"/>
                                                        <feOffset dy="1"/>
                                                        <feGaussianBlur stdDeviation="2"/>
                                                        <feComposite in2="hardAlpha" operator="out"/>
                                                        <feColorMatrix type="matrix"
                                                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                                 result="effect1_dropShadow_1921_35"/>
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                                 in2="effect1_dropShadow_1921_35" result="shape"/>
                                                    </filter>
                                                </defs>
                                            </svg>
                                            {
                                                lieu?.publicTransports
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        <div style={{
                            width: "100%",
                            borderRadius: "20px",
                            padding: "25px",
                            backgroundColor: "white",
                            boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
                            marginBottom: "30px",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "#102E3E",
                                boxSizing: "border-box",
                            }}>Territoires
                            </div>
                            <div style={{
                                fontSize: "14px",
                                color: "#858585",
                                marginBottom: "24px",
                                boxSizing: "border-box",
                            }}>Ce lieu est présent sur leur territoire.
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                boxSizing: "border-box",
                            }}>
                                <img style={{
                                    width: "36px",
                                    height: "36px",
                                    borderRadius: "50%",
                                    marginRight: "12px",
                                    boxSizing: "border-box",
                                    objectFit: "contain",
                                    backgroundColor: "white",
                                }} src={`${uri}/files/${lieuTerritoire?.logo}`} alt="logo"/>
                                <div style={{
                                    fontSize: "14px",
                                    color: "#102E3E",
                                    boxSizing: "border-box",
                                }}>{
                                    lieuTerritoire?.name
                                }</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lieu-proximite">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "24px",
                        boxSizing: "border-box",
                    }}>
                        <div onClick={() => lieuxScrollL()} style={{
                            position: "absolute",
                            left: "-20px",
                            top: "140px",
                            cursor: "pointer",
                            boxSizing: "border-box",
                        }}>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="19.5" transform="rotate(180 20 20)" fill="#F4F4F7"
                                        fill-opacity="0.9" stroke="#CCCCCC"/>
                                <path d="M24 28L14.9761 20.7809C14.4757 20.3805 14.4757 19.6195 14.9761 19.2191L24 12"
                                      stroke="#102E3E" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div onClick={() => lieuxScrollR()} style={{
                            position: "absolute",
                            right: "10px",
                            top: "140px",
                            cursor: "pointer",
                            boxSizing: "border-box",
                        }}>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="19.5" fill="#F4F4F7" fill-opacity="0.9" stroke="#CCCCCC"/>
                                <path d="M16 12L25.0239 19.2191C25.5243 19.6195 25.5243 20.3805 25.0239 20.7809L16 28"
                                      stroke="#102E3E" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <div style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: "#102E3E",
                            marginRight: "68px",
                            boxSizing: "border-box",
                        }}>Lieux à proximité
                        </div>
                    </div>
                    <div ref={placesRef} style={{
                        display: "flex",
                        width: "100%",
                        minHeight: "200px",
                        overflowX: "auto",
                        //invisibiliser la scrollbar
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },

                        scrollBehavior: "smooth",
                        marginBottom: "50px",
                        boxSizing: "border-box",
                    }}>{
                        //si pas de lieux afficher loader
                        lieux === null &&
                        "Il n'y a malheuresement pas encore de lieux à proximité de celui-ci."
                    }
                        {
                            lieux?.map((lieu, index) => {
                                return <div onMouseEnter={() => setLieuHover(index)}
                                            onMouseLeave={() => setLieuHover(null)}
                                            onClick={() => {
                                                navigate("/lieu/"+lieu.name)
                                            }}
                                            style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    cursor: "pointer",
                                    padding: "5px",
                                    backgroundColor: lieuHover === index ? "#E8EDF2" : "",
                                    marginRight: index < lieux.length - 1 ? "10px" : "0",
                                    borderRadius: "15px",
                                }}>
                                    <img style={{
                                        width: "200px",
                                        height: "200px",
                                        borderRadius: "14px",
                                        marginBottom: "13px",
                                        objectFit: "cover",
                                    }} src={uri+"/files/" + lieu.imgs[0]} alt="vignette"/>
                                    <div style={{
                                        fontSize: "15px",
                                        color: "#131D2E",
                                        overflowX: "hidden",
                                        maxWidth: "200px",
                                    }}>{lieu.name}</div>
                                    <div style={{
                                        fontSize: "13px",
                                        color: "#868686",
                                    }}>{lieu.territoire}</div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Lieu;